import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';
import { MixpanelDomain, MixpanelEventNames } from 'context/mix-panel/mix-panel-types';
import { cancelPolicy, PolicyClientCancellationOptions } from 'policies/actions/cancel-policy';
import { getPolicyById } from 'policies/actions/get-policy-by-id';
import { Policy } from 'policies/domain/policy';
import { PolicyStatus } from 'policies/domain/policy-status';
import { ManagementStepKeys, ManagementView } from 'policy-management/policy-management-scene';
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { usePolicyManagementUrl } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';

interface Props {
  policy: Policy | undefined;
  isLoadingPolicy: boolean;
  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
  setPolicy: (policy: Policy) => void;
}

const cancellationOptions = Object.values(PolicyClientCancellationOptions).map((o) => ({ label: o, value: o }));

export enum CancelPolicyInputs {
  CancellationType = 'CancellationType',
  Reason = 'reason',
}

export interface CancelPolicyData {
  [CancelPolicyInputs.CancellationType]?: PolicyClientCancellationOptions;
  [CancelPolicyInputs.Reason]?: string;
}

export const CancelPolicy = (props: Props) => {
  const { embedParams } = useEmbedParamsContext();
  const { track } = useMixpanel();

  const { environment, organizationId, auth } = embedParams;
  const { policy, isLoadingPolicy, setPolicy } = props;
  const { siteConfig } = useSiteConfigContext();
  const submitButtonRef = useRef<any>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const history = useHistory();
  const policyDetailsUrl = usePolicyManagementUrl({ view: ManagementView.PolicyDetails, policyId: policy?.policyId });
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: CancelPolicyInputs.CancellationType,
  });

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });
  form.watch();

  useEffect(() => {
    track(MixpanelEventNames.PageSelected, {
      domain: MixpanelDomain.Management,
      pageName: 'Cancel policy',
    });
  }, []);

  const { execute, error, isLoading } = usePromiseLazy(async () => {
    if (!policy) {
      throw new Error('Please ensure that you have an active policy selected');
    }

    const formValues = form.getValues() as CancelPolicyData;
    const data = {
      cancellationType: formValues[CancelPolicyInputs.CancellationType] || PolicyClientCancellationOptions.Other,
      reason: formValues[CancelPolicyInputs.Reason] || '',
    };
    const { policyId } = policy;
    await cancelPolicy({ auth, data, environment, organizationId, policyId });

    track(MixpanelEventNames.PolicyCancelled, {
      domain: MixpanelDomain.Management,
      policyId,
      policyNumber: policy.policyNumber,
      reason: data.reason,
    });

    const updatedPolicy = await getPolicyById({ auth, environment, organizationId, policyId: policy.policyId });
    setPolicy(updatedPolicy);

    setIsRedirecting(true);
    history.push(policyDetailsUrl);
    props.setCurrentStepKey(ManagementStepKeys.PolicyDetails);
  }, [policy]);

  if (!isLoadingPolicy && (!policy || policy.status === PolicyStatus.Cancelled)) {
    return (
      <ErrorAlert title={'Policy already cancelled'} error={new Error('Ensure you have an active policy selected')} />
    );
  }

  return (
    <FormWrapperStyle>
      {error && <ErrorAlert title='Failed to cancel policy' error={error} />}
      <form onSubmit={form.handleSubmit(() => execute())}>
        <BeneficiaryDetailsStyle style={{ paddingBottom: 60 }}>
          <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
            <StyledBeneficiaryCol sm={12}>
              <SelectField
                clearable={true}
                options={cancellationOptions}
                label='Reason'
                name={CancelPolicyInputs.CancellationType}
                form={form}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                placeholder=''
                isTouched={false}
                disableScrollToElement={false}
                hideDivider={true}
                disableNextButton={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: CancelPolicyInputs.CancellationType,
                  } as InputFieldDisplayProperties
                }
              />
            </StyledBeneficiaryCol>
          </StaticFormWithTitleRowStyle>
          <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
            <StyledBeneficiaryCol sm={12}>
              <InputField
                label='Describe any extra information about this cancellation'
                name={CancelPolicyInputs.Reason}
                defaultValue=''
                isTouched={false}
                form={form}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                placeholder=''
                disableScrollToElement={false}
                hideDivider={true}
                disableNextButton={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: CancelPolicyInputs.CancellationType,
                  } as InputFieldDisplayProperties
                }
              />
            </StyledBeneficiaryCol>
          </StaticFormWithTitleRowStyle>
          <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
          <SuccessButton
            disabled={!form.formState.isValid || isLoading || isRedirecting}
            siteConfig={siteConfig}
            onClick={() => submitButtonRef.current.click()}
            fullWidth={true}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color={'white'} />
              </span>
            )}
            <span>Cancel policy</span>
          </SuccessButton>
        </BeneficiaryDetailsStyle>
      </form>
    </FormWrapperStyle>
  );
};
