import React from 'react';
import styled from 'styled-components';
import { YoutubeEmbed } from 'components/YouTube';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModule } from 'product-modules/domain/product-module';
import { useSiteConfigContext } from 'style-context';
import { usePromise } from 'shared/hooks/promise';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { LandingPageError } from './landing-page-error';
import { LandingPageMainContent } from './landing-page-main-content';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import { LoadingImage } from 'rootstrap/components-old/loaders/loading-lines';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { MixpanelDomain, MixpanelEventNames, MixpanelStepNames } from 'context/mix-panel/mix-panel-types';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';

export const LandingPage = (props: { isLoading: boolean; error: Error | undefined; productModule?: ProductModule }) => {
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const [videoDisplayed, setVideoDisplayed] = React.useState(false);
  const { track } = useMixpanel();

  const wording = siteConfig?.landing.wording;

  const watchVideo = siteConfig?.landing.displayOptionalSections.watchVideo;

  const { embedSessionId } = useEmbedSessionContext();

  // Landing page params
  const youTubeVideoId = siteConfig?.landing.links.youTubeVideoId;

  const { isLoading: isCreatingEmbedSession } = usePromise(async () => {
    if (embedParams.productModuleKey) {
      track(MixpanelEventNames.EmbedSessionInitiated, {
        domain: MixpanelDomain.Management,
        page: MixpanelStepNames.LandingPage,
      });
    }
  }, [embedSessionId, embedParams.productModuleKey]);

  const isLoadingPageMount = !siteConfig;

  if (isLoadingPageMount && !props.error) {
    return (
      <LoadingWrapper>
        <LoadingImage lines={[100, 100, 100]} />;
      </LoadingWrapper>
    );
  }

  return (
    <div style={{ height: '100vh' }}>
      {props.error && (
        <div style={{ marginTop: 30 }}>
          <LandingPageError error={props.error} />
        </div>
      )}

      {siteConfig && videoDisplayed && <MobileVideoPlayingBackground />}
      {videoDisplayed && youTubeVideoId && watchVideo && (
        <YoutubeEmbed
          title={wording?.videoTitle}
          embedId={youTubeVideoId}
          onCloseClicked={() => {
            setVideoDisplayed(false);
          }}
        />
      )}
      <LandingPageWrapper siteConfig={siteConfig}>
        <LandingPageMainContent
          isLoading={props.isLoading}
          isCreatingEmbedSession={isCreatingEmbedSession}
          setVideoDisplayed={(videoDisplayed) => {
            setVideoDisplayed(videoDisplayed);
          }}
        />
      </LandingPageWrapper>
    </div>
  );
};

const LandingPageWrapper = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  height: 100%;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};

  background: ${({ siteConfig }) => `url(${siteConfig?.landing.images.background}) no-repeat center center fixed`};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media ${devices.mobileL} {
    padding-top: 0%;
  }

  padding-top: 5%;
`;

const MobileVideoPlayingBackground = styled.div`
  @media ${devices.tablet} {
    background-color: rgba(109, 109, 110, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const LoadingWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 70%;

  @media ${devices.tablet} {
    width: 80%;
  }

  @media ${devices.mobileL} {
    width: 100%;
  }
`;
