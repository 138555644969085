import { IconCheckmark } from 'assets/icon-checkmark';
import React, { useEffect, useState } from 'react';
import { Size, TextButton } from 'rootstrap/components/button/styles';
import { Checkbox, StyledCheckboxLabel } from 'rootstrap/components/forms/new-fields/checkbox-field';
import { GeneralModal } from 'rootstrap/components/modal/modal';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, getLink, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { ConsentAcceptCheckboxStyle, ConsentSplashContainer } from './styles/consent-section-styles';
import Markdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { useBaseUrl } from 'shared/api';
import { devices } from 'rootstrap/global-styles/devices';
import { scrollTo, scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';
import { GetPolicyIssuingFlowStepFromStepIndex, IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';
import { useMixPanelTrack } from 'context/mix-panel/mix-panel-context';
import { MixpanelStepNames } from 'context/mix-panel/mix-panel-types';

export const ConsentSection = (props: {
  setIsConsentChecked: (DebitAccountPermission: boolean) => void;
  isConsentChecked: boolean;
  content: string;
  isRequired: boolean;
  priorStepKey?: IssuingSceneStepKeys;
  step: IssuingSceneStepKeys;
  setStepProgress: (stepProgress: string) => void;
  setPriorStepKey: (stepProgress: string) => void;
  isLoading: boolean;
  stepOrder: Record<IssuingSceneStepKeys, number>;
  sectionIndices: number[];
}) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    setIsConsentChecked,
    isConsentChecked,
    priorStepKey,
    step,
    setStepProgress,
    setPriorStepKey,
    isLoading,
    stepOrder,
    sectionIndices,
  } = props;
  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const baseUrl = useBaseUrl();

  const { startedTrack } = useMixPanelTrack();

  const exitRedirect =
    step === StaticSceneStepKeys.PrePersonalDetailsConsent
      ? getLink({ link: siteConfig?.prePersonalDetailsCompliance?.links.exitRedirect })
      : getLink({ link: siteConfig?.prePaymentCompliance?.links.exitRedirect });

  useEffect(() => {
    if (!props.isRequired && !isLoading) {
      setStepProgress(
        GetPolicyIssuingFlowStepFromStepIndex({
          step: stepOrder[step] + 1,
          issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
          sectionIndices,
        }),
      );

      if (priorStepKey && stepOrder[priorStepKey] > stepOrder[step]) {
        setPriorStepKey(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder[step],
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            sectionIndices,
          }),
        );
        setStepProgress(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder[step] - 1,
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            sectionIndices,
          }),
        );
      }
    }
    if (props.isRequired) {
      startedTrack({
        stepName:
          step === StaticSceneStepKeys.PrePersonalDetailsConsent
            ? MixpanelStepNames.ConsentPrePersonalDetails
            : MixpanelStepNames.ConsentPrePayment,
      });
    }
    // 👇️ scroll to top on page load
    scrollToTopOfPage();
  }, [isLoading]);

  useEffect(() => {
    const splashElement = document.getElementById('fullscreen-modal-splash-heading-container');

    if (splashElement) {
      splashElement.style.backgroundColor = getColor({ siteConfig, color: 'backgroundHighlight' });
    }

    return () => {
      if (splashElement) {
        splashElement.style.backgroundColor = getColor({ siteConfig, color: 'primary' });
      }
    };
  }, []);

  useEffect(() => {
    isConsentChecked && scrollTo({ scrollToId: 'insurer-disclaimer-container' });
  }, [isConsentChecked]);

  return (
    <>
      <GeneralModal
        primaryButtonClicked={() => {
          exitRedirect ? (window.parent.location.href = exitRedirect) : history.push(baseUrl);
        }}
        primaryButtonText={'Exit'}
        secondaryButtonClicked={() => setIsExitModalOpen(false)}
        secondaryButtonText={'Cancel'}
        subTitle={'All saved progress will be lost'}
        title={'Are you sure you would like to exit'}
        isOpen={isExitModalOpen}
        isVertical={false}
        buttonColorTheme={getColor({ color: 'highlight', siteConfig })}
      />
      <div style={{ paddingTop: 20, marginTop: -320 }}>
        <ConsentSplashContainer id='consent-splash-container' siteConfig={siteConfig}>
          <>
            <ConsentPanel siteConfig={siteConfig}>
              <ConsentContentsStyle siteConfig={siteConfig}>
                <Markdown children={props.content} className='markdown' />
              </ConsentContentsStyle>
              <ConsentFooterStyle siteConfig={siteConfig}>
                <TextButton siteConfig={siteConfig} fullWidth size={Size.lg} onClick={() => setIsExitModalOpen(true)}>
                  Decline and exit
                </TextButton>
                <ConsentAcceptCheckboxStyle
                  id='debit-account-permission'
                  style={{ marginBottom: 10, marginTop: 9 }}
                  onClick={(e) => {
                    setIsConsentChecked(!isConsentChecked);
                  }}
                >
                  <ConsentStyledCheckboxLabel siteConfig={siteConfig} check>
                    <Checkbox
                      style={{ borderColor: getColor({ color: 'disabled', siteConfig }), caretColor: 'transparent' }}
                      icon={<IconCheckmark />}
                      siteConfig={siteConfig}
                      inputmode='none'
                      isActive={true}
                      isChecked={isConsentChecked}
                      onChange={() => {
                        setIsConsentChecked(!isConsentChecked);
                      }}
                      checked={isConsentChecked}
                      name='consentChecked'
                    />
                    <span
                      id='accept-consent-button'
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsConsentChecked(!isConsentChecked);
                      }}
                    >
                      Accept
                    </span>
                  </ConsentStyledCheckboxLabel>
                </ConsentAcceptCheckboxStyle>
              </ConsentFooterStyle>
            </ConsentPanel>
          </>
        </ConsentSplashContainer>
      </div>
    </>
  );
};

const ConsentStyledCheckboxLabel = styled(StyledCheckboxLabel)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${globalStyles.spacing.default};

  span {
    font-size: 14px;
    font-weight: bold;
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    padding-left: ${globalStyles.spacing.sm};
    display: flex;
    align-items: center;

    @media ${devices.mobileL} {
      margin-right: 5px;
    }
  }
`;

const ConsentFooterStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  border-top: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
  display: grid;
  padding-top: ${globalStyles.spacing.default};
  grid-template-columns: 1fr 1fr;
  padding-left: ${globalStyles.spacing.default};
  align-items: center;

  ${TextButton} {
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    font-weight: bold;
  }
`;

const ConsentContentsStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  margin-left: ${globalStyles.spacing.default};
  margin-right: ${globalStyles.spacing.default};
  padding-right: 10px;
  padding-bottom: 20px;

  display: block;
  max-height: 300px;
  overflow: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    padding-right: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
    border-radius: 20px;
    margin-right: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9ea0a3;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9ea0a3;
    border-radius: 20px;
  }
`;

export const PremiumDisplay = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  p {
    font-size: 20px;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  div {
    font-size: 34px;
    font-weight: 400;
  }
`;

const ConsentPanel = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: block;
  padding-top: ${globalStyles.spacing.default};
  padding-bottom: ${globalStyles.spacing.default};
  @media ${devices.mobileL} {
    padding-bottom: 0px;
  }

  flex-direction: column;
  flex: 1;
  align-items: center;
`;
