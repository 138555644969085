import React, { useState } from 'react';
import { FormOverlay, FormOverlayHeader, FormOverlayHeaderContents } from 'rootstrap/components-old/forms/form-overlay';
import {
  FormOverLay,
  FormOverlayTitleWrapper,
  SpacerLeft,
  SpacerRight,
} from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FormOverlayContentWrapper } from 'rootstrap/components/modal/styles';
import { ArrowIconWrapper } from 'rootstrap/components/progress-bar/progress-bar';
import { useSiteConfigContext } from 'style-context';
import { ActiveElement, scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { ArrowIcon } from 'assets/arrow-icon';
import { Beneficiary, BeneficiaryEntityType } from 'beneficiaries/domain/beneficiary';
import { Title } from 'general/title';
import { Gender } from 'models/gender';
import { Cellphone, Identification } from 'policyholder/domain/policyholder';
import { Moment } from 'moment';
import { BeneficiaryPaymentDetails } from 'payment-methods/domain/payee-bank-details';
import { ProductModule } from 'product-modules/domain/product-module';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { BeneficiaryDetailsInputs, BeneficiaryDetailsSection } from './beneficiary-details';
import {
  BeneficiarySummaryFullScreenModalHeader,
  BeneficiarySummaryTitleBody,
  BeneficiarySummaryTitleContents,
} from 'beneficiaries/styles/create-or-update-beneficiary';
import { FullScreenModalOffset } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { isColorDark } from 'utils';
import { getColor } from 'site-config';

interface CreateOrUpdateBeneficiaryParams {
  toggle: () => void;
  productModule?: ProductModule;
  beneficiary: Beneficiary | undefined;
  beneficiaries: Beneficiary[];
  setBeneficiaries: (beneficiaries: Beneficiary[]) => void;
}

export const CreateOrUpdateBeneficiary = (params: CreateOrUpdateBeneficiaryParams) => {
  const { siteConfig } = useSiteConfigContext();
  const { toggle, productModule, beneficiary, beneficiaries, setBeneficiaries } = params;
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: BeneficiaryDetailsInputs.Title,
  });

  const color = isColorDark(getColor({ siteConfig, color: 'backgroundHighlight' })) ? Colors.White : Colors.Body;
  return (
    <div style={{ height: '100vh' }}>
      <MainLayout>
        <MainLayoutContent>
          <Content>
            <FlexContainer>
              <FormOverlay>
                <FormOverlayHeader extendedWidth close={() => null}>
                  <FormOverlayHeaderContents siteConfig={siteConfig}>
                    <FullScreenModalOffset />
                    {
                      <FormOverlayTitleWrapper id='full-screen-header'>
                        <BeneficiarySummaryFullScreenModalHeader siteConfig={siteConfig} size={'20px'}>
                          <ArrowIconWrapper
                            onClick={() => {
                              scrollTo({
                                scrollToId: 'form-overlay-content-wrapper',
                                containerId: 'form-overlay-content-wrapper',
                              });
                              toggle();
                            }}
                          >
                            <ArrowIcon id='progress-bar-arrow-back-button' color={color} size={20} />
                          </ArrowIconWrapper>
                          <BeneficiarySummaryTitleBody>
                            <BeneficiarySummaryTitleContents
                              siteConfig={siteConfig}
                              id='create-or-update-beneficiary-title'
                            >
                              {beneficiary ? 'Beneficiary details' : 'Add a beneficiary'}
                            </BeneficiarySummaryTitleContents>
                          </BeneficiarySummaryTitleBody>
                        </BeneficiarySummaryFullScreenModalHeader>
                      </FormOverlayTitleWrapper>
                    }
                    <FullScreenModalOffset />
                  </FormOverlayHeaderContents>
                </FormOverlayHeader>
                <div style={{ height: '100%', overflow: 'scroll' }} id='form-overflow-content-wrapper-beneficiaries'>
                  <FormOverlayContentWrapper id='form-overlay-content-wrapper'>
                    <FormOverLay>
                      <SpacerLeft />
                      <div className='main'>
                        <FormWrapperStyle>
                          <BeneficiaryDetailsSection
                            beneficiaries={beneficiaries}
                            toggle={toggle}
                            setBeneficiaries={(beneficiaries) => setBeneficiaries(beneficiaries)}
                            productModule={productModule}
                            prefillValues={{}}
                            setActiveElement={(activeElement) => setActiveElement(activeElement)}
                            activeElement={activeElement}
                            beneficiary={beneficiary}
                          />
                        </FormWrapperStyle>
                      </div>
                      <SpacerRight />
                    </FormOverLay>
                  </FormOverlayContentWrapper>
                </div>
              </FormOverlay>
            </FlexContainer>
          </Content>
        </MainLayoutContent>
      </MainLayout>
    </div>
  );
};

export interface BeneficiaryFormData {
  firstName: string;
  lastName: string;
  title?: Title;
  gender?: Gender;
  id?: Identification;
  dateOfBirth?: Moment;
  email: string;
  relationship: string;
  cellphone?: Cellphone;
  paymentDetails?: BeneficiaryPaymentDetails;
  companyName?: string;
  registrationNumber?: string;
  entityType?: BeneficiaryEntityType;
}
