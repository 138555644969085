import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';
import { MixpanelDomain, MixpanelEventNames } from 'context/mix-panel/mix-panel-types';
import { createDebitOrderPaymentMethod } from 'payment-methods/actions/create-debit-order-payment-method';
import { Bank } from 'payment-methods/domain/payee-bank-details';
import {
  AccountType,
  DebitOrderPaymentMethod,
  DebitOrderPaymentMethodDetails,
  PaymentMethodAll,
  PaymentMethodType,
} from 'payment-methods/domain/payment-method';
import { updatePolicy } from 'policies/actions/update-policy';
import { Policy } from 'policies/domain/policy';
import { DebitOrderDetailsFormData } from 'policy-issuing/payment/views/debit-order-payment';
import { Policyholder } from 'policyholder/domain/policyholder';
import React, { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { AccountTypeSelectField } from 'rootstrap/components/forms/new-fields/extended-components/account-type-select-field';
import { banks } from 'rootstrap/components/forms/new-fields/extended-components/bank-select-field';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { NumberField } from 'rootstrap/components/forms/new-fields/number-field';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';

export enum DebitOrderPaymentInputs {
  BankName = 'bankName',
  AccountNumber = 'accountNumber',
  AccountHolder = 'accountHolder',
  BranchCode = 'branchCode',
  BillingDay = 'billingDay',
  AccountType = 'accountType',
}

export interface DebitOrderPaymentFormData {
  [DebitOrderPaymentInputs.BillingDay]: number;
  [DebitOrderPaymentInputs.BankName]: Bank;
  [DebitOrderPaymentInputs.AccountNumber]: string;
  [DebitOrderPaymentInputs.AccountHolder]: string;
  [DebitOrderPaymentInputs.BranchCode]: string;
  [DebitOrderPaymentInputs.AccountType]: AccountType;
}

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  setPolicy: (policy: Policy) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  paymentMethod: DebitOrderPaymentMethod | undefined;
}

export const DebitOrderPaymentMethodForm = (props: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    activeElement,
    setActiveElement,
    setIsUpdatingOrCreatingPaymentMethod,
    policy,
    setPolicy,
    paymentMethod,
    policyholder,
    setPaymentMethod,
  } = props;

  const submitButtonRef = useRef<any>();
  const isTouched = false;

  const { embedParams } = useEmbedParamsContext();
  const { track } = useMixpanel();

  const { auth, organizationId, environment } = embedParams;

  const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;

  const form = useForm<DebitOrderPaymentFormData>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        [DebitOrderPaymentInputs.AccountHolder]: paymentMethod?.bankDetails?.accountHolder,
        [DebitOrderPaymentInputs.AccountNumber]: paymentMethod?.bankDetails?.accountNumber,
        [DebitOrderPaymentInputs.AccountType]: paymentMethod?.bankDetails?.accountType,
        [DebitOrderPaymentInputs.BankName]: paymentMethod?.bankDetails?.bank,
        [DebitOrderPaymentInputs.BranchCode]: paymentMethod?.bankDetails?.branchCode,
        [DebitOrderPaymentInputs.BillingDay]: policy.billingDay,
      }),
      [],
    ),
  });

  form.watch();

  const { execute, error, isLoading } = usePromiseLazy(async (formData: DebitOrderPaymentFormData) => {
    const formValues = form.getValues() as DebitOrderPaymentFormData;
    const billingDay = formValues[DebitOrderPaymentInputs.BillingDay];

    if (canEditPaymentMethod && bankDetailsHaveChanged(formValues, paymentMethod?.bankDetails)) {
      const details: DebitOrderDetailsFormData = {
        accountHolder: formValues.accountHolder,
        accountNumber: formValues.accountNumber.toString(),
        bankName: formValues.bankName,
        branchCode: formValues.branchCode,
        type: PaymentMethodType.DebitOrder,
        accountType: formValues.accountType as AccountType,
        billingDay: formValues[DebitOrderPaymentInputs.BillingDay],
      };

      const updatedPaymentMethod = await createDebitOrderPaymentMethod({
        auth,
        details,
        environment,
        organizationId,
        policyholderId: policyholder.policyholderId,
        policyId: policy.policyId,
      });

      track(MixpanelEventNames.PaymentMethodEdited, {
        domain: MixpanelDomain.Management,
        type: PaymentMethodType.DebitOrder,
        policyId: policy.policyId,
        policyNumber: policy.policyNumber,
      });

      setPaymentMethod(updatedPaymentMethod);
    }

    if (billingDay !== policy.billingDay && canEditBillingDay) {
      const updatedPolicy = await updatePolicy({
        environment,
        organizationId,
        auth,
        data: { billingDay },
        policyId: policy.policyId,
      });

      setPolicy(updatedPolicy);
    }

    setIsUpdatingOrCreatingPaymentMethod(false);
  }, []);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <FormWrapperStyle>
      <BeneficiaryDetailsStyle style={{ paddingBottom: 60, paddingTop: 0 }}>
        <form onSubmit={form.handleSubmit((data: DebitOrderPaymentFormData) => execute(data))}>
          <>
            {canEditPaymentMethod && (
              <>
                <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                  <StyledBeneficiaryCol sm={12}>
                    <SelectField
                      clearable={true}
                      isTouched={!!paymentMethod?.bankDetails?.bank}
                      disableScrollToElement={false}
                      hideDivider={true}
                      options={banks}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      name={DebitOrderPaymentInputs.BankName}
                      label='Bank'
                      form={form}
                      defaultValue={paymentMethod?.bankDetails?.bank}
                      placeholder={'Bank'}
                      disableActiveElement={true}
                      disableNextButton={true}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: DebitOrderPaymentInputs.AccountNumber,
                        } as InputFieldDisplayProperties
                      }
                    />
                  </StyledBeneficiaryCol>
                </StaticFormWithTitleRowStyle>
                <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                  <StyledBeneficiaryCol sm={12}>
                    <NumberField
                      isTouched={!!paymentMethod?.bankDetails?.accountNumber}
                      disableScrollToElement={false}
                      name={DebitOrderPaymentInputs.AccountNumber}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      label='Account number'
                      form={form}
                      defaultValue={Number(paymentMethod?.bankDetails?.accountNumber)}
                      disableNextButton={true}
                      hideDivider={true}
                      placeholder={'Account number'}
                      disableActiveElement={true}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: DebitOrderPaymentInputs.AccountHolder,
                        } as InputFieldDisplayProperties
                      }
                    />
                  </StyledBeneficiaryCol>
                </StaticFormWithTitleRowStyle>
                <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                  <StyledBeneficiaryCol sm={12}>
                    <InputField
                      isTouched={!!paymentMethod?.bankDetails?.accountHolder}
                      disableScrollToElement={false}
                      name={DebitOrderPaymentInputs.AccountHolder}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      label='Account holder'
                      form={form}
                      defaultValue={paymentMethod?.bankDetails?.accountHolder}
                      placeholder={'Account holder*'}
                      hideDivider={true}
                      disableNextButton={true}
                      disableActiveElement={true}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: DebitOrderPaymentInputs.BranchCode,
                        } as InputFieldDisplayProperties
                      }
                    />
                  </StyledBeneficiaryCol>
                </StaticFormWithTitleRowStyle>
                <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                  <StyledBeneficiaryCol sm={12}>
                    <NumberField
                      isTouched={!!paymentMethod?.bankDetails?.branchCode}
                      disableScrollToElement={false}
                      name={DebitOrderPaymentInputs.BranchCode}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      label='Branch code'
                      form={form}
                      defaultValue={Number(paymentMethod?.bankDetails?.branchCode)}
                      placeholder={'Branch code'}
                      disableNextButton={true}
                      disableActiveElement={true}
                      hideDivider={true}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: DebitOrderPaymentInputs.BillingDay,
                        } as InputFieldDisplayProperties
                      }
                    />
                  </StyledBeneficiaryCol>
                </StaticFormWithTitleRowStyle>
                <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                  <StyledBeneficiaryCol sm={12}>
                    <AccountTypeSelectField
                      clearable={true}
                      isTouched={isTouched}
                      disableScrollToElement={false}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      name={DebitOrderPaymentInputs.AccountType}
                      label='Account type'
                      form={form}
                      defaultValue={paymentMethod?.bankDetails?.accountType}
                      placeholder={'Account type'}
                      disableActiveElement={true}
                      disableNextButton={true}
                      hideDivider={true}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: '',
                        } as InputFieldDisplayProperties
                      }
                    />
                  </StyledBeneficiaryCol>
                </StaticFormWithTitleRowStyle>
              </>
            )}
            {canEditBillingDay && (
              <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                <StyledBeneficiaryCol sm={12}>
                  <BillingDaySelectField
                    isTouched={isTouched}
                    clearable={true}
                    disableScrollToElement={true}
                    name={DebitOrderPaymentInputs.BillingDay}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                    ]}
                    label='Debit day'
                    form={form}
                    defaultValue={policy.billingDay}
                    prefillValue={DebitOrderPaymentInputs.BillingDay}
                    placeholder={'Debit day'}
                    disableActiveElement={true}
                    hideDivider={true}
                    displayProperties={
                      {
                        activeElement: {
                          elementId: '',
                        },
                        setActiveElement,
                        nextComponentName: '',
                      } as InputFieldDisplayProperties
                    }
                  />
                </StyledBeneficiaryCol>
              </StaticFormWithTitleRowStyle>
            )}
          </>
          <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
          <LongButtonSuccess
            id='manage-payment-methods-submit-button'
            siteConfig={siteConfig}
            onClick={() => submitButtonRef.current.click()}
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            {paymentMethod ? 'Update' : 'Add'}
          </LongButtonSuccess>
        </form>
      </BeneficiaryDetailsStyle>
    </FormWrapperStyle>
  );
};

const bankDetailsHaveChanged = (
  details: DebitOrderPaymentFormData,
  existing: DebitOrderPaymentMethodDetails | undefined,
) => {
  if (!existing) {
    return true;
  }

  return !(
    details[DebitOrderPaymentInputs.AccountNumber].toString() === existing.accountNumber &&
    details[DebitOrderPaymentInputs.AccountType] === existing.accountType &&
    details[DebitOrderPaymentInputs.BankName] === existing.bank &&
    details[DebitOrderPaymentInputs.BranchCode].toString() === existing.branchCode.toString() &&
    details[DebitOrderPaymentInputs.AccountHolder] === existing.accountHolder
  );
};
