import React, { useEffect } from 'react';
import { Modal, ModalBody } from 'rootstrap/components/modal';
import { CardBody } from 'rootstrap/components/card';
import { HighlightButton, LongButtonBlank, Size } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';
import { useHistory } from 'react-router-dom';
import { useBaseUrl } from 'shared/api';
import { getColor, getWording } from 'site-config';
import { Spacing } from 'rootstrap/global-styles';
import styled from 'styled-components';
import { scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';
import { useMixPanelTrack, useMixpanel } from 'context/mix-panel/mix-panel-context';
import {
  MixpanelDomain,
  MixpanelEventNames,
  MixpanelOutcome,
  MixpanelStepNames,
} from 'context/mix-panel/mix-panel-types';

export const ScreeningQuestionsModal = (props: { onBackClicked: () => void }) => {
  const { siteConfig } = useSiteConfigContext();
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const highlight = getColor({ siteConfig, color: 'highlight' });
  const exitRedirect = siteConfig?.quote.links.exitRedirect;
  const allowRetry = siteConfig?.quote.displayOptionalSections.screeningQuestionsRetry;
  const screeningQuestionsRejected = getWording({ wording: siteConfig?.quote.wording.screeningQuestionsRejected });
  // const mixpanel = useMixpanel();
  const { track } = useMixpanel();
  const { startedTrack } = useMixPanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.ScreeningQuestions,
      extraProperties: {
        outcome: MixpanelOutcome.Failure,
      },
    });
    scrollToTopOfPage();
  }, []);

  return (
    <Modal centered isOpen fullWidthOnMobile>
      <ModalBody>
        <CardBody style={{ padding: Spacing.md, textAlign: 'center' }}>
          <StyledH5>We're sorry...</StyledH5>
          <p>{screeningQuestionsRejected}</p>
          <div style={{ textAlign: 'center' }}>
            <HighlightButton
              siteConfig={siteConfig}
              fullWidth
              size={Size.lg}
              onClick={() => {
                track(MixpanelEventNames.StepExited, {
                  step: MixpanelStepNames.ScreeningQuestions,
                  domain: MixpanelDomain.Sales,
                });
                exitRedirect ? (window.parent.location.href = exitRedirect) : history.push(baseUrl);
              }}
            >
              Exit
            </HighlightButton>
            {allowRetry && (
              <LongButtonBlank
                style={{ marginBottom: 0, marginTop: 0 }}
                color={highlight}
                siteConfig={siteConfig}
                onClick={() => {
                  track(MixpanelEventNames.StepRetried, {
                    step: MixpanelStepNames.ScreeningQuestions,
                    domain: MixpanelDomain.Sales,
                  });
                  props.onBackClicked();
                }}
              >
                Back
              </LongButtonBlank>
            )}
          </div>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

const StyledH5 = styled.h5`
  font-size: 20px;
`;
