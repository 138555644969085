import { createAlterationPackages } from 'alteration-hooks/actions/create-alteration-package';
import { AlterationPackage } from 'alteration-hooks/domain/alteration-package';
import { ProductModuleDefinitionAlterationHook } from 'alteration-hooks/domain/product-module-definition-alteration-hooks';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';
import { MixpanelDomain, MixpanelEventNames, MixpanelOutcome } from 'context/mix-panel/mix-panel-types';
import { Policy } from 'policies/domain/policy';
import { QuotePackageDetailsStyle } from 'policy-issuing/quotes/views/create-quote-package-step';
import { PolicySummaryErrorPage } from 'policy-management/policy-details/views/policy-summary-error-page';
import { PolicyManagementSceneBaseParams } from 'policy-management/policy-management-scene';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import React, { useEffect, useRef, useState } from 'react';
import { WrappedLoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle, RootSchemaForm } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { toInputDataV2, toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { ApiError } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { useQueryParams } from 'shared/hooks/url';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { debounce } from 'utils';

interface Props extends SteppedFullScreenModalComponentParams, PolicyManagementSceneBaseParams {
  policy: Policy | undefined;
  alterationHooks: ProductModuleDefinitionAlterationHook[];
  productModuleDefinition: ProductModuleDefinition | undefined;
  isLoading: boolean;
  setAlterationPackages: (alterationPackage: AlterationPackage[] | undefined) => void;
  alterationPackages: AlterationPackage[] | undefined;
  setCreateAlterationPackageError: (error: ApiError | undefined) => void;
  setIsCreatingAlterationPackage: (isCreatingAlterationPackage: boolean) => void;
  rootSchemaFormAlterationPackageRef: React.MutableRefObject<any>;
}

export const CreateAlterationPackageScene = (params: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const { queryParams } = useQueryParams<{ alteration_hook_key: string | undefined }>();
  const disableSteppedComponents = siteConfig?.styles.disableSteppedComponents;
  const { track } = useMixpanel();

  useEffect(() => {
    track(MixpanelEventNames.PageSelected, {
      domain: MixpanelDomain.Management,
      pageName: `Alteration ${queryParams.alteration_hook_key}`,
    });
  }, []);

  // base id's and keys
  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;

  const {
    setAlterationPackages: setAlterationPackage,
    policy,
    alterationHooks,
    isLoading,
    setCreateAlterationPackageError,
    setIsCreatingAlterationPackage,
    error,
    rootSchemaFormAlterationPackageRef,
  } = params;

  const [alterationHooksSchemaFormData, setAlterationHooksSchemaFormData] = useState(policy?.module);

  const [alterationPackageError, setAlterationPackageError] = useState<ApiError | undefined>(undefined);

  const applyAlterationPackageRef = useRef<any>();
  const [selectedAlterationHook, setSelectedAlterationHook] = useState<
    ProductModuleDefinitionAlterationHook | undefined
  >(alterationHooks.find(({ key }) => key === queryParams.alteration_hook_key));

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    setAlterationHooksSchemaFormData(policy?.module);
  }, [policy]);

  useEffect(() => {
    setCreateAlterationPackageError(alterationPackageError);
  }, [alterationPackageError]);

  useEffect(() => {
    setSelectedAlterationHook(alterationHooks.find(({ key }) => key === queryParams.alteration_hook_key));
  }, [queryParams.alteration_hook_key, alterationHooks]);

  const createAlterationPackage = usePromiseLazy(async (data: JSONObject) => {
    setAlterationPackage(undefined);

    if (!policy) {
      throw new Error('Missing policy Id');
    }

    const alterationPackages = await createAlterationPackages({
      auth,
      data,
      organizationId,
      alterationHookKey: queryParams.alteration_hook_key,
      policyId: policy.policyId,
      environment,
    });

    setAlterationPackage(alterationPackages);

    track(MixpanelEventNames.AlterationPackageReturned, {
      policyId: policy.policyId,
      policyNumber: policy.policyNumber,
      alteration: queryParams.alteration_hook_key,
      domain: MixpanelDomain.Management,
      outcome: alterationPackages ? MixpanelOutcome.Success : MixpanelOutcome.Failure,
      quotes: alterationPackages?.map(({ currency, monthlyPremium, sumAssured, status }) => ({
        currency,
        monthlyPremium,
        sumAssured,
        status,
      })),
    });
  }, []);

  if (error) {
    return <PolicySummaryErrorPage />;
  }

  if (
    !alterationHooks.find(({ key }) => key === queryParams.alteration_hook_key) ||
    isLoading ||
    !selectedAlterationHook?.productModuleDefinitionSchema?.json
  ) {
    return (
      <>
        <FormDetailsWrapper siteConfig={siteConfig}>
          <WrappedLoadingInputs count={3} />
        </FormDetailsWrapper>
      </>
    );
  }

  return (
    <>
      <FormWrapperStyle>
        <RootSchemaForm
          isLastStep={true}
          ref={rootSchemaFormAlterationPackageRef}
          defaultValues={toInputDataV2({
            schema: selectedAlterationHook?.productModuleDefinitionSchema?.json || [],
            formData: alterationHooksSchemaFormData || policy?.module || {},
            context: alterationHooksSchemaFormData || policy?.module || {},
          })}
          setIsLoading={setIsCreatingAlterationPackage}
          secondaryStepStaticData={undefined}
          isSecondaryStep={false}
          disableSteppedComponents={!!disableSteppedComponents}
          prefillValues={undefined}
          onCompletedActiveComponentName={'alteration-footer-steps'}
          onError={() => setAlterationPackage(undefined)}
          isTouched={true}
          submitOnChange
          currency={params.productModuleDefinition?.settings.billing.currency}
          onSubmit={async (formData) => {
            debounce(
              'get-alteration-package',
              async () => {
                const { error } = await createAlterationPackage.execute({
                  ...toOutputData({
                    formData,
                    schema: selectedAlterationHook?.productModuleDefinitionSchema?.json || [],
                  }),
                });

                if (error) {
                  policy &&
                    track(MixpanelEventNames.AlterationPackageReturned, {
                      policyId: policy.policyId,
                      policyNumber: policy.policyNumber,
                      alteration: queryParams.alteration_hook_key,
                      domain: MixpanelDomain.Management,
                      outcome: MixpanelOutcome.Failure,
                      quotes: undefined,
                    });
                }

                setAlterationPackageError(error);
                setIsCreatingAlterationPackage(false);
              },
              500,
            );
          }}
          schema={selectedAlterationHook?.productModuleDefinitionSchema?.json || []}
          submitButtonRef={applyAlterationPackageRef}
        />
      </FormWrapperStyle>
    </>
  );
};

export const AlterationPackageDetailsStyle = styled(QuotePackageDetailsStyle)`
  font-weight: bold;
  margin-bottom: ${Spacing.md};
  font-size: 20px;

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
