import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { ActionExitedPayload, generateActionExitedProperties } from './events/action-exited.payload';
import { ActionStartedPayload, generateActionStartedProperties } from './events/action-started.payload';
import {
  AlterationPackageReturnedPayload,
  generateAlterationPackageReturnedProperties,
} from './events/alteration-package-returned.payload';
import {
  BeneficiariesUpdatedPayload,
  generateBeneficiariesUpdatedProperties,
} from './events/beneficiaries-updated.payload';
import { ClaimLinkAccessedPayload, generateClaimLinkAccessedProperties } from './events/claim-link-accessed.payload';
import {
  EmbedSessionInitiatedPayload,
  generateEmbedSessionInitiatedProperties,
} from './events/embed-session-initiated.payload';
import { generatePageSelectedProperties, PageSelectedPayload } from './events/page-selection.payload';
import {
  generatePaymentMethodEditedProperties,
  PaymentMethodEditedPayload,
} from './events/payment-method-edited.payload';
import {
  generateAlterationPackageAppliedProperties,
  PolicyAlterationAppliedPayload,
} from './events/policy-alteration-applied.payload';
import { generatePolicyCancelledProperties, PolicyCancelledPayload } from './events/policy-cancelled.payload';
import {
  generateQuotePackageReturnedProperties,
  QuotePackageReturnedPayload,
} from './events/quote-package-returned.payload';
import { generateStepActionProperties, StepActionPayload } from './events/step-started.payload';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { AlterationPackageStatus } from 'alteration-hooks/domain/alteration-package';

export enum MixpanelDomain {
  Sales = 'Embed sales',
  Management = 'Embed management',
}

export enum MixpanelEventNames {
  PolicyCancelled = 'Policy cancelled',
  PageSelected = 'Page selection',
  EmbedSessionInitiated = 'Embed session initiated',
  AlterationPackageReturned = 'Alteration package returned',
  PolicyAlterationApplied = 'Policy alteration applied',
  PaymentMethodEdited = 'Payment method edited',
  BeneficiariesUpdated = 'Beneficiaries updated',
  ClaimLinkAccessed = 'Claim link accessed',
  ActionExited = 'Action exited',
  StepStarted = 'Step started',
  StepCompleted = 'Step completed',
  QuotePackageReturned = 'Quote package returned',
  ActionStarted = 'Action started',
  StepExited = 'Step exited',
  StepRetried = 'Step retried',
}

export interface MixpanelEventPropertiesMap {
  [MixpanelEventNames.PolicyCancelled]: PolicyCancelledPayload;
  [MixpanelEventNames.PageSelected]: PageSelectedPayload;
  [MixpanelEventNames.EmbedSessionInitiated]: EmbedSessionInitiatedPayload;
  [MixpanelEventNames.AlterationPackageReturned]: AlterationPackageReturnedPayload;
  [MixpanelEventNames.PolicyAlterationApplied]: PolicyAlterationAppliedPayload;
  [MixpanelEventNames.PaymentMethodEdited]: PaymentMethodEditedPayload;
  [MixpanelEventNames.BeneficiariesUpdated]: BeneficiariesUpdatedPayload;
  [MixpanelEventNames.ClaimLinkAccessed]: ClaimLinkAccessedPayload;
  [MixpanelEventNames.ActionExited]: ActionExitedPayload;
  [MixpanelEventNames.StepStarted]: StepActionPayload;
  [MixpanelEventNames.StepCompleted]: StepActionPayload;
  [MixpanelEventNames.ActionStarted]: ActionStartedPayload;
  [MixpanelEventNames.QuotePackageReturned]: QuotePackageReturnedPayload;
  [MixpanelEventNames.StepExited]: StepActionPayload;
  [MixpanelEventNames.StepRetried]: StepActionPayload;
}

export const MixPanelEventGenerators: {
  [K in MixpanelEventNames]?: (payload: MixpanelEventPropertiesMap[K]) => MixpanelEventPropertiesMap[K];
} = {
  [MixpanelEventNames.PolicyCancelled]: generatePolicyCancelledProperties,
  [MixpanelEventNames.AlterationPackageReturned]: generateAlterationPackageReturnedProperties,
  [MixpanelEventNames.PageSelected]: generatePageSelectedProperties,
  [MixpanelEventNames.PolicyAlterationApplied]: generateAlterationPackageAppliedProperties,
  [MixpanelEventNames.PaymentMethodEdited]: generatePaymentMethodEditedProperties,
  [MixpanelEventNames.BeneficiariesUpdated]: generateBeneficiariesUpdatedProperties,
  [MixpanelEventNames.ClaimLinkAccessed]: generateClaimLinkAccessedProperties,
  [MixpanelEventNames.ActionExited]: generateActionExitedProperties,
  [MixpanelEventNames.StepStarted]: generateStepActionProperties,
  [MixpanelEventNames.StepCompleted]: generateStepActionProperties,
  [MixpanelEventNames.QuotePackageReturned]: generateQuotePackageReturnedProperties,
  [MixpanelEventNames.ActionStarted]: generateActionStartedProperties,
  [MixpanelEventNames.EmbedSessionInitiated]: generateEmbedSessionInitiatedProperties,
};

export enum MixpanelOutcome {
  Success = 'success',
  Failure = 'failure',
}

export interface MixpanelExtraProperties {
  outcome?: MixpanelOutcome;
  policyholder?: {
    identificationType: string | undefined;
    identificationCountry: string | undefined;
    gender: string | undefined;
    dateOfBirth: string | undefined;
    city: string | undefined;
    country: string | undefined;
  };
  beneficiaries?: {
    identificationType: string | undefined;
    identificationCountry: string | undefined;
    gender: string | undefined;
    dateOfBirth: string | undefined;
    relationship: string | undefined;
  }[];
  quotes?:
    | {
        billingFrequency: PolicyBillingFrequency;
        currency: Currency;
        sumAssured: number;
        suggestedPremium: number;
        quotePackageId: string;
      }[]
    | {
        error: string;
      };
  alterations?:
    | {
        status: AlterationPackageStatus;
        currency: Currency;
        monthlyPremium: number;
        sumAssured: number;
        alterationPackageId: string;
      }[]
    | {
        error: string;
      };
}

export enum MixpanelStepNames {
  LandingPage = 'landing_page',
  ScreeningQuestions = 'screening_questions',
  QuoteStep = 'quote_step',
  ConsentPrePersonalDetails = 'consent_pre_personal_details',
  PersonalDetails = 'personal_details',
  Application = 'application',
  Beneficiaries = 'beneficiaries',
  ConsentPrePayment = 'consent_pre_payment',
  Payment = 'payment',
  Confirmation = 'confirmation',
}
