import { MixpanelBasePayload } from '../mix-panel-context';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { MixpanelOutcome } from '../mix-panel-types';

export interface QuotePackageReturnedPayload extends MixpanelBasePayload {
  step: string;
  outcome: MixpanelOutcome;
  quotes:
    | {
        currency: string;
        sumAssured: number;
        billingFrequency: PolicyBillingFrequency;
        quotePackageId: string;
        suggestedPremium: number;
      }[]
    | undefined;
}

export function generateQuotePackageReturnedProperties(
  payload: QuotePackageReturnedPayload,
): QuotePackageReturnedPayload {
  return payload;
}
