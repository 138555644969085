export const sanitizeUrl = (url: string) => {
  const itemsToRemove = ['token', 'api_key'];

  const baseUrl = url.split('?')[0];
  const queryString = url.split('?')[1];

  if (!queryString) return baseUrl;

  const queryParams = queryString.split('&').filter((param) => {
    return !itemsToRemove.some((item) => param.startsWith(`${item}=`));
  });

  const sanitizedUrl = baseUrl + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');

  return sanitizedUrl;
};
