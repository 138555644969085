import { AlterationPackageStatus } from 'alteration-hooks/domain/alteration-package';
import { MixpanelPolicyPayload } from './generic-entity-types';
import { MixpanelOutcome } from '../mix-panel-types';

export interface AlterationPackageReturnedPayload extends MixpanelPolicyPayload {
  alteration: string;
  outcome: MixpanelOutcome;
  quotes:
    | {
        currency: string;
        sumAssured: number;
        monthlyPremium: number;
        status: AlterationPackageStatus;
      }[]
    | undefined;
}

export function generateAlterationPackageReturnedProperties(
  payload: AlterationPackageReturnedPayload,
): AlterationPackageReturnedPayload {
  return payload;
}
