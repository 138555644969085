import { MixpanelBasePayload } from '../mix-panel-context';

export interface EmbedSessionInitiatedPayload extends MixpanelBasePayload {
  page: string;
}

export function generateEmbedSessionInitiatedProperties(
  payload: EmbedSessionInitiatedPayload,
): EmbedSessionInitiatedPayload {
  return payload;
}
