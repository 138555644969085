import { PhoneIcon } from 'assets/phone-icon';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';
import { MixpanelDomain, MixpanelEventNames } from 'context/mix-panel/mix-panel-types';
import { Policy } from 'policies/domain/policy';
import React, { useEffect } from 'react';
import { HighlightButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import {
  getColor,
  getDisplayOptionalSections,
  getLink,
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
} from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface Props {
  policy: Policy | undefined;
}

export const CreateClaim = (props: Props) => {
  const { policy } = props;
  const { siteConfig } = useSiteConfigContext();
  const { track } = useMixpanel();

  const openClaimLink = getLink({ link: siteConfig?.management?.claim.links.openClaim });
  const contactNumberWording = getWording({ wording: siteConfig?.management?.claim.wording.contactNumber });
  const callToAction = getWording({ wording: siteConfig?.management?.claim.wording.callToAction });
  const displayCallToAction = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.management?.claim.displayOptionalSections.callToAction,
  });
  const displayContactNumber = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.management?.claim.displayOptionalSections.contactNumber,
  });

  useEffect(() => {
    track(MixpanelEventNames.PageSelected, {
      domain: MixpanelDomain.Management,
      pageName: 'Make a claim',
    });
  }, []);

  return (
    <ContentContainer siteConfig={siteConfig}>
      <p id='claim-description' className='large-text'>
        Want to make a claim?
      </p>
      {displayCallToAction && (
        <HighlightButton
          fullWidth
          siteConfig={siteConfig}
          onClick={() => {
            policy &&
              track(MixpanelEventNames.ClaimLinkAccessed, {
                domain: MixpanelDomain.Management,
                policyId: policy.policyId,
                policyNumber: policy.policyNumber,
              });
            window.parent.location.href = openClaimLink;
          }}
        >
          {callToAction}
        </HighlightButton>
      )}
      {displayContactNumber && (
        <div className='contact-info'>
          <p>
            <PhoneIcon className='phone-icon' fill={getColor({ siteConfig, color: 'highlight' })} />
            or call us on <a href={`tel:${contactNumberWording}`}>{contactNumberWording}</a>
          </p>
        </div>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  margin-top: ${globalStyles.spacing.default};

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }

  .large-text {
    font-size: ${globalStyles.fontSize.title};
    margin-bottom: ${globalStyles.spacing.lg};
  }

  .btn {
    margin-bottom: ${globalStyles.spacing.default};
  }

  .contact-info {
    a {
      color: ${({ siteConfig }) => siteConfig?.styles.colors.highlight};
    }

    svg {
      margin-right: 12px;
    }
  }
`;
