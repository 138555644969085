import { AccountType } from './payment-method';

export interface BeneficiaryPaymentDetails {
  type: PaymentType;
  details: BankAccount;
}

export enum PaymentType {
  EFT = 'eft',
  CARD = 'card',
}

export interface BankAccount {
  bankName: Bank;
  branchCode: string;
  accountType: AccountType;
  accountNumber: string;
}

export interface DbBankAccount {
  bank_name: Bank;
  branch_code: string;
  account_type: AccountType;
  account_number: string;
}

export enum Bank {
  ABSA = 'absa',
  AfricanBank = 'african_bank',
  AlBaraka = 'al_baraka',
  BankOfLisbon = 'bank_of_lisbon',
  Bidvest = 'bidvest',
  Capitec = 'capitec',
  DiscoveryBank = 'discovery_bank',
  FNB = 'fnb',
  Investec = 'investec',
  Ithala = 'ithala',
  Mercantile = 'mercantile',
  Nedbank = 'nedbank',
  OldMutual = 'old_mutual',
  Postbank = 'postbank',
  Sasfin = 'sasfin',
  StandardBank = 'standard_bank',
  TymeBank = 'tyme_bank',
  Ubank = 'ubank',
  // United Kingdom Banks
  HSBCHoldings = 'hsbc_holdings',
  LloydsBankingGroup = 'lloyds_banking_group',
  RoyalBankOfScotlandGroup = 'royal_bank_of_scotland_group',
  Barclays = 'barclays',
  StandardChartered = 'standard_chartered',
  SantanderUK = 'santander_uk',
  NationwideBuildingSociety = 'nationwide_building_society',
  Schroders = 'schroders',
  CloseBrothersGroupPlc = 'close_brothers_group_plc',
  CoventryBuildingSociety = 'coventry_building_society',
}

export const BankInformation: { [key: string]: any } = {
  absa: { name: 'ABSA', branch: '632005', key: 'absa' },
  african_bank: { name: 'African Bank', branch: '430000', key: 'african_bank' },
  al_baraka: { name: 'Al Baraka', branch: '800000', key: 'al_baraka' },
  bank_of_lisbon: { name: 'Bank of Lisbon', branch: '450105', key: 'bank_of_lisbon' },
  bidvest: { name: 'Bidvest', branch: '462005', key: 'bidvest' },
  capitec: { name: 'Capitec', branch: '470010', key: 'capitec' },
  discovery_bank: { name: 'Discovery Bank', branch: '679000', key: 'discovery_bank' },
  fnb: { name: 'FNB', branch: '250655', key: 'fnb' },
  investec: { name: 'Investec', branch: '580105', key: 'investec' },
  ithala: { name: 'Ithala', branch: '430000', key: 'ithala' },
  mercantile: { name: 'Mercantile', branch: '450105', key: 'mercantile' },
  nedbank: { name: 'Nedbank', branch: '198765', key: 'nedbank' },
  old_mutual: { name: 'Old Mutual', branch: '462005', key: 'old_mutual' },
  postbank: { name: 'Postbank', branch: '460005', key: 'postbank' },
  sasfin: { name: 'Sasfin', branch: '683000', key: 'sasfin' },
  standard_bank: { name: 'Standard Bank', branch: '051001', key: 'standard_bank' },
  tyme_bank: { name: 'Tyme Bank', branch: '678910', key: 'tyme_bank' },
  ubank: { name: 'Ubank', branch: '431010', key: 'ubank' },
  // UK banks
  hsbc_holdings: { name: 'HSBC Holdings', key: 'hsbc_holdings' },
  lloyds_banking_group: { name: 'Lloyds Banking Group', key: 'lloyds_banking_group' },
  royal_bank_of_scotland_group: { name: 'Royal Bank of Scotland Group', key: 'royal_bank_of_scotland_group' },
  barclays: { name: 'Barclays', key: 'barclays' },
  standard_chartered: { name: 'Standard Chartered', key: 'standard_chartered' },
  santander_uk: { name: 'Santander UK', key: 'santander_uk' },
  nationwide_building_society: { name: 'Nationwide Building Society', key: 'nationwide_building_society' },
  schroders: { name: 'Schroders', key: 'schroders' },
  close_brothers_group_plc: { name: 'Close Brothers Group Plc', key: 'close_brothers_group_plc' },
  coventry_building_society: { name: 'Coventry Building Society', key: 'coventry_building_society' },
};

export const prettyBankName = (bank: Bank): string => {
  return BankInformation[bank]?.name ?? bank;
};
