import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { useEffect, useState } from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle, RootSchemaForm } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { toInputDataV2, toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { useDraftDefinition } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { debounce } from 'utils';
import { createQuotes } from '../actions/create-quotes';
import { Quote } from '../domain/quote';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import _, { cloneDeep, merge } from 'lodash';
import { PrefillValues } from 'shared/domain/prefill-values';
import { scrollTo, scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';
import { useMixpanel, useMixPanelTrack } from 'context/mix-panel/mix-panel-context';
import {
  MixpanelDomain,
  MixpanelEventNames,
  MixpanelOutcome,
  MixpanelStepNames,
} from 'context/mix-panel/mix-panel-types';

interface Props extends SteppedFullScreenModalComponentParams {
  sectionIndex: number;
  quoteSchemaFormDataParts: { [key: number]: JSONObject | undefined };
  productModuleDefinitionQuoteSchema?: ProductModuleDefinitionSchema;
  createQuoteButtonRef: React.MutableRefObject<any>;
  productModuleDefinition: ProductModuleDefinition | undefined;
  screeningQuestionsData?: JSONObject;
  isCompleted: boolean;
  prefillValues: PrefillValues;
  rootSchemaFormRef: React.MutableRefObject<any>;
  isLastStep: boolean;
  stepKey: string;
  isLoadingCreateQuote: boolean;
  setQuoteSchemaFormDataPart: (data: JSONObject | undefined) => void;
  setQuotePackages: (data?: Quote[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setCreateQuoteError: (error: any) => void;
  setIsValid: (isValid: boolean) => void;
  setIsLoadingMainPage: (isLoading: boolean) => void;
}

export const CreateQuotePackageStep = (params: Props) => {
  const targetDraftProductModuleDefinition = useDraftDefinition();
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { embedSessionId } = useEmbedSessionContext();
  const { startedTrack } = useMixPanelTrack();
  const { track } = useMixpanel();

  useEffect(() => {
    startedTrack({
      stepName: `${MixpanelStepNames.QuoteStep}_${sectionIndex}`,
    });
    scrollToTopOfPage();
  }, []);

  const { auth, organizationId, productModuleKey, environment } = embedParams;
  const {
    sectionIndex,
    quoteSchemaFormDataParts,
    setQuoteSchemaFormDataPart,
    setQuotePackages,
    productModuleDefinitionQuoteSchema,
    setIsLoading,
    setCreateQuoteError,
    screeningQuestionsData,
    createQuoteButtonRef,
    isCompleted,
    prefillValues,
    setIsValid,
    rootSchemaFormRef,
    isLastStep,
    stepKey,
  } = params;

  const [isLoadingMainPage, setIsLoadingMainPage] = useState<boolean>(false);
  const disableSteppedComponents = siteConfig?.styles.disableSteppedComponents;
  const enableSessionPersistence = siteConfig?.settings.enableSessionPersistence;
  const isTouched = isCompleted || !!(prefillValues?.quote && Object.keys(prefillValues?.quote).length !== 0);
  const onCompletedActiveComponentName = 'insurer-disclaimer-container';

  useEffect(() => {
    // Scroll to top on page load
    setIsLoadingMainPage(true);
    params.setIsLoadingMainPage(true);
    window.scrollTo({ top: 0, left: 0 });
    debounce(
      'quote-page-mount',
      () => {
        setIsLoadingMainPage(false);
        params.setIsLoadingMainPage(false);
      },
      1000,
    );
  }, [stepKey]);

  const { execute: onSubmitClicked } = usePromiseLazy(
    async (params: { formData: JSONObject; isLastElement: boolean }) => {
      const { formData, isLastElement } = params;

      if (isLastElement) {
        scrollTo({
          scrollToId: onCompletedActiveComponentName,
          containerId: 'form-overlay-content-wrapper',
          customBlock: 'end',
        });
      }

      setCreateQuoteError(undefined);
      setQuoteSchemaFormDataPart(formData);

      // Merge all form data parts
      const mergedFormData =
        Object.values({
          ...quoteSchemaFormDataParts,
          [sectionIndex]: formData,
        }).reduce((acc, curr) => merge(acc, cloneDeep(curr)), {}) || {};

      if (isLastStep && mergedFormData) {
        setIsLoading(true);

        const data = {
          ...screeningQuestionsData,
          ...toOutputData({
            formData: mergedFormData || {},
            schema: productModuleDefinitionQuoteSchema?.json || [],
          }),
        };

        const { result: quotes, error } = await createQuote.execute(data);

        setCreateQuoteError(error);
        setQuotePackages(quotes);
        setIsLoading(false);

        if (error) {
          track(MixpanelEventNames.QuotePackageReturned, {
            step: `${MixpanelStepNames.QuoteStep}_${sectionIndex}`,
            domain: MixpanelDomain.Sales,
            outcome: MixpanelOutcome.Failure,
            quotes: undefined,
          });
        }

        if (isLastElement) {
          scrollTo({
            scrollToId: onCompletedActiveComponentName,
            containerId: 'form-overlay-content-wrapper',
            customBlock: 'end',
          });
        }
      } else {
        // Not the last step: just update the form data and clear any existing quotes
        setQuotePackages(undefined);
      }
      setIsLoading(false);
    },
    [quoteSchemaFormDataParts, screeningQuestionsData, isLastStep],
  );

  const createQuote = usePromiseLazy(async (data: JSONObject) => {
    setQuotePackages(undefined);

    const quotes = await createQuotes({
      auth,
      data: {
        type: productModuleKey,
        ...data,
      },
      embedSessionId: enableSessionPersistence ? embedSessionId : undefined,
      organizationId,
      targetDraftProductModuleDefinition,
      environment,
    });

    track(MixpanelEventNames.QuotePackageReturned, {
      step: `${MixpanelStepNames.QuoteStep}_${sectionIndex}`,
      domain: MixpanelDomain.Sales,
      outcome: MixpanelOutcome.Success,
      quotes: quotes.map(({ billingFrequency, currency, sumAssured, suggestedPremium, quotePackageId }) => ({
        billingFrequency,
        currency,
        sumAssured,
        suggestedPremium,
        quotePackageId,
      })),
    });

    return quotes;
  }, []);

  if (!productModuleDefinitionQuoteSchema) {
    return (
      <>
        <FormWrapperStyle>
          <FormDetailsWrapper siteConfig={siteConfig}>
            <LoadingInputs count={3} />
          </FormDetailsWrapper>
        </FormWrapperStyle>
      </>
    );
  }

  const schema = (productModuleDefinitionQuoteSchema.json || []).filter(
    ({ sectionIndex: idx }) => idx === sectionIndex || (idx === undefined && sectionIndex === 1),
  );

  const mergedQuoteData =
    Object.values(quoteSchemaFormDataParts).reduce((acc, curr) => merge(cloneDeep(acc), cloneDeep(curr)), {}) || {};

  const defaultValues = {
    ...toInputDataV2({
      formData: { ...prefillValues?.quote },
      schema: productModuleDefinitionQuoteSchema.json,
    }),
    ...mergedQuoteData,
  };

  if (isLoadingMainPage) {
    return <LoadingInputs count={1} />;
  }

  return (
    <>
      <FormWrapperStyle>
        <RootSchemaForm
          ref={rootSchemaFormRef}
          defaultValues={defaultValues}
          isSecondaryStep={false}
          secondaryStepStaticData={undefined}
          disableSteppedComponents={!!disableSteppedComponents}
          prefillValues={params.prefillValues?.quote}
          isLastStep={isLastStep}
          onCompletedActiveComponentName={onCompletedActiveComponentName}
          onError={() => {
            if (isLastStep) {
              setQuotePackages(undefined);
            }
          }}
          key={stepKey}
          setIsValid={(isValid) => setIsValid(isValid)}
          isTouched={isTouched}
          submitOnChange
          setIsLoading={() => undefined}
          currency={params.productModuleDefinition?.settings.billing.currency}
          onSubmit={async (formData, isLastElement) => {
            setQuoteSchemaFormDataPart(formData);
            setIsLoading(true);

            debounce(
              `set-quote-step-${sectionIndex}-data`,
              () => {
                onSubmitClicked({ formData, isLastElement });
              },
              1000,
            );
          }}
          schema={schema}
          submitButtonRef={createQuoteButtonRef}
        />
      </FormWrapperStyle>
    </>
  );
};

export const QuotePackageDetailsStyle = styled.div`
  font-weight: bold;
  margin-bottom: ${Spacing.md};
  font-size: 20px;

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
