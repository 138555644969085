import { updateApplicationBillingDay } from 'applications/actions/update-application-billing-day';
import { createExternalPaymentMethod } from 'payment-methods/actions/create-external-payment-method';
import { getExternalPaymentMethodTypes } from 'payment-methods/actions/get-external-payment-method-types';
import { issuePolicy } from 'policies/actions/issue-policy';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CardGroup } from 'rootstrap/components/card';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { applyPolicyDetailsToUrl, useConfirmationUrl, useBaseUrl, AuthTypes } from 'shared/api';
import { PaymentMethodPrefillParams } from 'shared/domain/prefill-values';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';
import { PaymentMethodProps } from '../payment-step';
import { handleRedirect } from '../utils/handle-payment-redirect';
import { utc as moment } from 'moment';
import { updateApplication } from 'applications/actions/update-application';
import { SubmitPaymentMethodLongButtonSuccess } from './styles';
import { scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { useMixPanelTrack } from 'context/mix-panel/mix-panel-context';
import { MixpanelStepNames } from 'context/mix-panel/mix-panel-types';

export enum ExternalPaymentInputs {
  BillingDay = 'billingDay',
}

export const ExternalPaymentMethod = (params: PaymentMethodProps) => {
  const { prefillValues, application, setPaymentMethodIsValid, policyholder, setActiveElement, isConfirmationSkipped } =
    params;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { stepCompletedTrack } = useMixPanelTrack();

  const { auth, environment, organizationId } = embedParams;
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const confirmationUrl = useConfirmationUrl();
  const canEditBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.editBillingDay);
  const displayBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.billingDay);
  const callToActionText = siteConfig?.payment.wording.callToAction || 'Pay now';

  const externalPaymentInputData = getExternalPaymentMethodInputData({
    billingDay: application?.billingDay,
    canEditBillingDay,
    prefillValues: prefillValues?.payment,
  });
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const paymentMethodSubmitButtonRef = useRef<any>();
  const [isTouched] = useState<boolean>(false);

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });

  form.watch();

  usePromise(async () => {
    setPaymentMethodIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  useEffect(() => {
    scrollTo({ scrollToId: 'bankName-form-group' });
  }, []);

  const { execute, error, isLoading } = usePromiseLazy(async (formData: ExternalPaymentMethodPrefillParams) => {
    if (!policyholder || !application) {
      throw new Error(`No ${!policyholder ? 'policyholder' : 'application'} found`);
    }

    const externalPaymentMethodTypes = await getExternalPaymentMethodTypes({
      auth,
      environment,
      organizationId,
    });

    await createExternalPaymentMethod({
      applicationId: application.applicationId,
      billingDay: formData.billingDay || 1,
      externalPaymentMethodType: externalPaymentMethodTypes[0].key,
      paymentReference:
        externalPaymentInputData.externalReference || `${policyholder.policyholderId}:${moment().toISOString()}`,
      policyholderId: policyholder.policyholderId,
      auth,
      environment,
      organizationId,
    });

    if (canEditBillingDay && application.billingDay !== formData.billingDay && displayBillingDay) {
      if (auth.type === AuthTypes.APIKey) {
        await updateApplication({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: formData.billingDay || 1,
          },
        });
      } else {
        await updateApplicationBillingDay({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: formData.billingDay || 1,
          },
        });
      }
    }

    const { policyId, policyholderId } = await issuePolicy({
      applicationId: application.applicationId,
      billingDay: formData.billingDay,
      auth,
      environment,
      organizationId,
    });

    stepCompletedTrack({
      stepName: MixpanelStepNames.Payment,
    });

    handleRedirect({
      applyPolicyDetailsToUrl,
      baseUrl,
      confirmationUrl,
      environment,
      history,
      isConfirmationSkipped,
      policyholderId,
      policyId,
      redirectOnCompletedUrl,
    });
  }, []);

  return (
    <FormWrapperStyle>
      <ErrorAlert error={error} />
      <CardGroup>
        <form onSubmit={form.handleSubmit((data: ExternalPaymentMethodPrefillParams) => execute(data))}>
          {displayBillingDay && (
            <Row>
              <Col sm={12}>
                <BillingDaySelectField
                  clearable={true}
                  isTouched={isTouched}
                  disableScrollToElement={true}
                  name={ExternalPaymentInputs.BillingDay}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  label='Debit day'
                  form={form}
                  isDisabled={!canEditBillingDay}
                  defaultValue={externalPaymentInputData.billingDay}
                  prefillValue={externalPaymentInputData.billingDay}
                  disableTitle={canEditBillingDay}
                  placeholder={'Debit day'}
                  disableActiveElement={true}
                  hideDivider={true}
                  displayProperties={
                    {
                      activeElement: {
                        elementId: '',
                      },
                      setActiveElement,
                      nextComponentName: '',
                    } as InputFieldDisplayProperties
                  }
                />
              </Col>
            </Row>
          )}
          <button style={{ display: 'none' }} ref={paymentMethodSubmitButtonRef} type='submit' />
          <SubmitPaymentMethodLongButtonSuccess
            siteConfig={siteConfig}
            onClick={() => paymentMethodSubmitButtonRef.current.click()}
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            {callToActionText}
          </SubmitPaymentMethodLongButtonSuccess>
        </form>
      </CardGroup>
    </FormWrapperStyle>
  );
};

export interface ExternalPaymentMethodPrefillParams {
  billingDay?: number;
  externalReference?: string;
}

const getExternalPaymentMethodInputData = (params: {
  prefillValues?: PaymentMethodPrefillParams;
  canEditBillingDay: boolean;
  billingDay: number | undefined;
}): ExternalPaymentMethodPrefillParams => {
  const { billingDay, canEditBillingDay, prefillValues } = params;
  return {
    billingDay: canEditBillingDay ? prefillValues?.debit_day || billingDay : billingDay,
    externalReference: prefillValues?.external_reference,
  };
};
