import { PaymentMethodType } from 'payment-methods/domain/payment-method';
import { MixpanelPolicyPayload } from './generic-entity-types';

export interface PaymentMethodEditedPayload extends MixpanelPolicyPayload {
  type: PaymentMethodType | 'collection-module' | 'billing-day';
}

export function generatePaymentMethodEditedProperties(payload: PaymentMethodEditedPayload): PaymentMethodEditedPayload {
  return payload;
}
