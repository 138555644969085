import { MixpanelBasePayload } from '../mix-panel-context';
import { MixpanelOutcome } from '../mix-panel-types';

export interface StepActionPayload extends MixpanelBasePayload {
  step: string;
  outcome?: MixpanelOutcome;
}

export function generateStepActionProperties(payload: StepActionPayload): StepActionPayload {
  return payload;
}
