import React, { ReactNode, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DisplayMarkdown, Field } from '../new-field';
import { InputFieldParams } from './input-field';
import { useSiteConfigContext } from 'style-context';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { Input } from 'reactstrap';
import { getColor } from 'site-config';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { isMobile } from 'react-device-detect';
import { RadioButtonOptionsContainer, RadioButtonWrapper, StyledRadioLabel } from './radio-button-field';

export const CheckboxButtonField = ({
  label,
  name,
  isDisabled,
  form,
  options,
  defaultValue,
  prefillAction,
  prefillValue,
  disableScrollToElement,
  hideDivider,
  displayProperties,
  validators,
  disableNextButton,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
  isLastComponent,
  isLastStep,
}: InputFieldParams<string> & { options: { label: ReactNode; value: any; isDisabled?: boolean }[] }) => {
  const checkedValue = form?.watch(name);
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = useState<boolean>(checkedValue !== undefined || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;
  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: options as { label: string; value: any }[],
    validate: (value) =>
      getValidationMessage({
        validators,
        value,
        props: undefined,
      }),
  });

  const doesValueMatchSelected = (selected: boolean, val: boolean) => selected === val;
  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
  }, [isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        disableActiveElement={disableActiveElement}
        isActive={isActive}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
        isMobile={isMobile}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          disableTitle={disableTitle}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
          }}
        >
          <Controller
            // Default form value
            defaultValue={defaultValue}
            name={name}
            control={form?.control}
            rules={{
              validate: (value) => {
                // Custom required validation: value must be true or false if required
                if (!hiddenComponent && isRequired && value !== true && value !== false) {
                  return 'Required';
                }
                // Additional custom validations if provided
                if (getValidationMessage) {
                  return getValidationMessage(value);
                }
                return true;
              },
            }}
            render={({ onChange }) => {
              return (
                <>
                  <RadioButtonWrapper
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <RadioButtonOptionsContainer disabled={isDisabled || disabledFromPrefill}>
                      {options.map((option, index) => {
                        const isOptionDisabled = disabledFromPrefill || isDisabled || option.isDisabled;
                        const cursor = isOptionDisabled ? 'not-allowed' : 'pointer';

                        console.log('checkedValue', checkedValue);
                        console.log('option.value', option.value);
                        return (
                          // "key" must be unique. Typically "option.value" or "index"
                          <StyledRadioLabel
                            key={`checkbox-button-option-${index}`}
                            borderColor={getSteppedFormLabelColor({
                              isActive: isActive || isTouched,
                              siteConfig,
                              color: Colors.Body,
                            })}
                            isSelected={doesValueMatchSelected(checkedValue, option.value)}
                            color={
                              isActive || isTouched
                                ? getColor({ siteConfig, color: 'highlight' })
                                : `${getColor({ siteConfig, color: 'highlight' })}80`
                            }
                            disableActiveElement={!!disableActiveElement}
                            isActive={isActive || isTouched}
                            siteConfig={siteConfig}
                            isFirstItem={index === 0}
                            isLastItem={index === options.length - 1}
                          >
                            <Input
                              id={name}
                              isActive={isActive}
                              name={name}
                              siteConfig={siteConfig}
                              disabled={isOptionDisabled}
                              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                onEnterKeyDown({
                                  e,
                                  nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                                  setFieldError: (errorMsg) => setFieldError(errorMsg),
                                  setActiveElement: (elementId) =>
                                    setActiveElement({
                                      elementId,
                                    }),
                                  getValue: () => form?.watch(name),
                                  validationFunction: getValidationMessage,
                                  validators,
                                  validationProps: undefined,
                                  setIsTouched: (isTouched) => setTouched(isTouched),
                                  isActive,
                                })
                              }
                              onChange={() => {
                                setTouched(true);
                                onChange(option.value);

                                const validationError = getValidationMessage({
                                  validators,
                                  value: option.value,
                                  props: undefined,
                                });
                                setFieldError(validationError);

                                if (!validationError) {
                                  setActiveElement &&
                                    setActiveElement({
                                      elementId: getNextComponentId({ ...displayProperties, key: name }),
                                    });
                                }
                              }}
                              value={option.value}
                              type='radio'
                              invalid={!!fieldError}
                              style={{ opacity: 1, cursor }}
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                            <div
                              className='text-container'
                              style={{
                                cursor,
                              }}
                            >
                              <span>
                                <DisplayMarkdown label={option.label} />
                              </span>
                            </div>
                          </StyledRadioLabel>
                        );
                      })}
                    </RadioButtonOptionsContainer>
                  </RadioButtonWrapper>
                </>
              );
            }}
          />
        </Field>

        {/* Step Navigation / Next Button */}
        {!hiddenComponent && (
          <SteppedFormButton
            isLastStep={isLastStep}
            isLastComponent={isLastComponent}
            disableActiveElement={!!disableActiveElement}
            submitOnChange={submitOnChange}
            hideDivider={hideDivider}
            hideBorder={hideBorder}
            setTouched={(value) => setTouched(value)}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(errorMsg) => setFieldError(errorMsg)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            disableNextButton={disableNextButton}
            validationProps={undefined}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};
