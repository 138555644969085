import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormOverLay, SpacerLeft, SpacerRight } from 'rootstrap/components/modal/styles/form-overlay-styles';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import styled from 'styled-components';
import { HighlightButton, SuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import { CheckCircle } from 'assets/check-circle-icon';
import { ProductModule } from 'product-modules/domain/product-module';
import { useSiteConfigContext } from 'style-context';
import {
  getColor,
  getDisplayOptionalSections,
  getLink,
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
} from 'site-config';
import { applyPolicyDetailsToUrl, useBaseUrl } from 'shared/api';
import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import {
  FormOverlay,
  FormOverlayFooter,
  FormOverlayFooterWrapper,
  FormOverlayHeader,
  OverlayFormInnerContentExtendedWidth,
} from 'rootstrap/components-old/forms/form-overlay';
import { SplashHeader } from 'rootstrap/components/splash-header/splash-header';
import { FormOverlayContentWrapper } from 'rootstrap/components/modal/styles';
import { PhoneIcon } from 'assets/phone-icon';
import { InsurerDisclaimerContainer } from 'general/insurer-disclaimer';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { useQueryParams } from 'shared/hooks/url';
import { Environment } from 'models/environment';
import { scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';
import { useMixPanelTrack } from 'context/mix-panel/mix-panel-context';
import { MixpanelStepNames } from 'context/mix-panel/mix-panel-types';

export const ConfirmationScene = (props: { productModule?: ProductModule }) => {
  const { siteConfig } = useSiteConfigContext();
  const { startedTrack } = useMixPanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.Confirmation,
    });
    scrollToTopOfPage();
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <MainLayout>
        <MainLayoutContent>
          <Content>
            <FlexContainer>
              <FormOverlay>
                <FormOverlayHeader extendedWidth close={() => null}>
                  <div style={{ display: 'flex', backgroundColor: getColor({ siteConfig, color: 'primary' }) }}></div>
                </FormOverlayHeader>
                <div style={{ height: '100%', overflow: 'scroll' }} id='form-overflow-content-wrapper-confirmation'>
                  <FormOverlayContentWrapper id='form-overlay-content-wrapper'>
                    <SplashHeader siteConfig={siteConfig} removeBorderRadius removeMarginBottom />
                    <FormOverLay>
                      <SpacerLeft />
                      <div className='main'>
                        <MainContent siteConfig={siteConfig} />
                      </div>
                      <SpacerRight />
                    </FormOverLay>
                  </FormOverlayContentWrapper>
                </div>
                <FormOverlayFooter siteConfig={siteConfig} disableBackgroundColor extendedWidth>
                  <FormOverlayFooterWrapper siteConfig={siteConfig} className='overlay-form-footer'>
                    <div className='spacer-left' />
                    <OverlayFormInnerContentExtendedWidth className={'overlay-form-inner-content-extended-width'}>
                      <InsurerDisclaimerContainer />
                    </OverlayFormInnerContentExtendedWidth>
                    <div className='spacer-right' />
                  </FormOverlayFooterWrapper>
                </FormOverlayFooter>
              </FormOverlay>
            </FlexContainer>
          </Content>
        </MainLayoutContent>
      </MainLayout>
    </div>
  );
};

const MainContent = (props: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => {
  const { siteConfig } = props;

  // Wording
  const wording = siteConfig?.confirmation.wording;
  const displayOptionalSections = siteConfig?.confirmation.displayOptionalSections;

  const titleWording = getWording({ wording: wording?.title });
  const subTitleWording = getWording({ wording: wording?.subTitle });
  const descriptionWording = getWording({ wording: wording?.description });
  const contactNumberWording = getWording({ wording: wording?.contactNumber });
  const secondarySubTitleWording = getWording({ wording: wording?.secondarySubTitle });
  const secondaryButtonWording = getWording({ wording: wording?.secondaryButton });
  const contactNumberDescriptionWording = getWording({ wording: wording?.contactNumberDescription });
  const primaryButtonWording = getWording({ wording: wording?.primaryButton });

  // Display conditions
  const displayContactNumber = getDisplayOptionalSections({
    displayOptionalSection: displayOptionalSections?.contactNumber,
  });
  const displayContactNumberDescription = getDisplayOptionalSections({
    displayOptionalSection: displayOptionalSections?.contactNumberDescription,
  });

  const displaySecondarySubTitleSubTitle = getDisplayOptionalSections({
    displayOptionalSection: displayOptionalSections?.secondarySubTitle,
  });

  const displayPrimaryButton = getDisplayOptionalSections({
    displayOptionalSection: displayOptionalSections?.primaryButton,
  });

  const displaySecondaryButton = getDisplayOptionalSections({
    displayOptionalSection: displayOptionalSections?.secondaryButton,
  });

  const openClaimLink = getLink({ link: siteConfig?.confirmation.links.openClaim });
  const { queryParams } = useQueryParams<{ policy_id: string; policyholder_id: string; environment: Environment }>();
  const { policy_id: policyId, policyholder_id: policyholderId, environment } = queryParams;
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const baseUrl = useBaseUrl();
  const history = useHistory();

  const exitRedirect =
    redirectOnCompletedUrl &&
    applyPolicyDetailsToUrl({ baseUrl: redirectOnCompletedUrl, environment, policyholderId, policyId });

  return (
    <div style={{ paddingTop: 20, marginTop: -272 }}>
      <FormWrapperStyle>
        <TitleSection>
          <h1 id='confirmation-page-title'>{titleWording}</h1>
          <div id='confirmation-page-sub-tile'>{subTitleWording}</div>
        </TitleSection>
      </FormWrapperStyle>
      <SplashContainer siteConfig={siteConfig}>
        <ContentContainer>
          <CheckCircle color={getColor({ siteConfig, color: 'highlight' })} />
          <div className='top-section'>
            <p>{descriptionWording}</p>
          </div>
          <hr />
          {displaySecondarySubTitleSubTitle && (
            <p id='confirmation-screen-want-to-make-a-claim' className='large-text'>
              {secondarySubTitleWording}
            </p>
          )}
          {displaySecondaryButton && (
            <HighlightButton
              fullWidth
              siteConfig={siteConfig}
              onClick={() => {
                window.parent.location.href = openClaimLink;
              }}
            >
              {secondaryButtonWording}
            </HighlightButton>
          )}
          {displayContactNumber && (
            <div className='contact-info'>
              <p>
                <PhoneIcon className='phone-icon' fill={getColor({ siteConfig, color: 'highlight' })} />
                {displayContactNumberDescription ? `${contactNumberDescriptionWording} ` : ''}
                {contactNumberWording}
              </p>
            </div>
          )}
        </ContentContainer>
      </SplashContainer>
      {displayPrimaryButton && (
        <ButtonContainer>
          <SuccessButton
            siteConfig={siteConfig}
            onClick={() => {
              exitRedirect ? (window.parent.location.href = exitRedirect) : history.push(baseUrl);
            }}
            fullWidth
          >
            {primaryButtonWording}
          </SuccessButton>
        </ButtonContainer>
      )}
    </div>
  );
};

const ContentContainer = styled.div`
  margin-top: ${globalStyles.spacing.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-right: 15%;
  margin-left: 15%;
  margin-bottom: ${globalStyles.spacing.default};

  .top-section {
    text-align: center;
    padding-top: ${globalStyles.spacing.default};
    padding-bottom: ${globalStyles.spacing.default};
    border-bottom: 2px solid ${globalStyles.colors.Neutral};
  }

  .large-text {
    font-size: ${globalStyles.fontSize.title};
    margin-bottom: ${globalStyles.spacing.sm};
  }

  .btn {
    margin-bottom: ${globalStyles.spacing.sm};
  }

  .contact-info {
    svg {
      margin-right: 12px;
    }
  }
`;

export const TitleSection = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  text-align: center;

  h1 {
    font-size: ${globalStyles.spacing.md};
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: white;
    margin-bottom: ${globalStyles.spacing.sm};
  }

  div {
    font-size: ${globalStyles.fontSize.title};
    font-weight: 400;
  }
`;

const ButtonContainer = styled(FormWrapperStyle)`
  margin-top: ${globalStyles.spacing.lg};
  margin-bottom: ${globalStyles.spacing.lg};
`;
