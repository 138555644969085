import { Quote } from 'policy-issuing/quotes/domain/quote';
import { StaticSceneStepKeys } from 'policy-issuing/utils';
import { ProductModule } from 'product-modules/domain/product-module';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultStepKeys } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { useBaseUrl } from 'shared/api';
import { PrefillValues } from 'shared/domain/prefill-values';
import { JSONObject } from 'shared/utils';
import { getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { CreateQuotePackageStep } from './create-quote-package-step';
import { useMixPanelTrack } from 'context/mix-panel/mix-panel-context';
import { MixpanelStepNames } from 'context/mix-panel/mix-panel-types';

interface ProductModuleAndSchema {
  productModuleDefinitionQuoteSchema: ProductModuleDefinitionSchema;
  productModule: ProductModule;
  productModuleDefinitionApplicationSchema: ProductModuleDefinitionSchema;
}

export const QuoteDetailsStep = (params: {
  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
  getPriorStepKey: () => SetStateAction<any | undefined>;
  onNextCompleted: (() => void | Promise<void>) | undefined;
  onBackCompleted: (() => void | Promise<void>) | undefined;
  isLoadingCreateQuote: boolean;
  quoteSchemaFormDataParts: { [key: number]: JSONObject | undefined };
  setQuoteSchemaFormDataParts: (data: { [key: number]: JSONObject | undefined }) => void;
  isQuoteCompletedSteps: { [key: number]: boolean };
  setIsQuoteCompletedSteps: (data: { [key: number]: boolean }) => void;
  isValidQuoteParts: { [key: number]: boolean };
  setIsValidQuoteParts: (data: { [key: number]: boolean }) => void;
  prefillValues: PrefillValues;
  setLoadingCreateQuote: (isLoading: boolean) => void;
  productModuleAndSchema: ProductModuleAndSchema | undefined;
  createQuoteButtonRef: React.MutableRefObject<HTMLButtonElement | undefined>;
  screeningQuestionsData: any;
  selectedQuote: Quote | undefined;
  setQuotePackages: (quote: Quote[] | undefined) => void;
  setCreateQuoteError: (error: any) => void;
  rootSchemaFormQuoteRef: React.MutableRefObject<any>;
  isQuoteCompleted: boolean;
  setIsQuoteCompleted: (isCompleted: boolean) => void;
  setIsLoadingBackButton: (isLoading: boolean) => void;
  setIsLoadingMainPage: (isLoading: boolean) => void;
  isLoadingMainPage: boolean;
  sectionIndices: number[];
}) => {
  const {
    isLoadingCreateQuote,
    quoteSchemaFormDataParts,
    setQuoteSchemaFormDataParts,
    isQuoteCompletedSteps,
    setIsQuoteCompletedSteps,
    isValidQuoteParts,
    setIsValidQuoteParts,
    prefillValues,
    setLoadingCreateQuote,
    setIsLoadingMainPage,
    productModuleAndSchema,
    createQuoteButtonRef,
    screeningQuestionsData,
    selectedQuote,
    setQuotePackages,
    setCreateQuoteError,
    rootSchemaFormQuoteRef,
    isQuoteCompleted,
    isLoadingMainPage,
    setIsLoadingBackButton,
    setIsQuoteCompleted,
    sectionIndices,
  } = params;

  const { siteConfig } = useSiteConfigContext();
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { stepCompletedTrack } = useMixPanelTrack();

  const quote = siteConfig?.quote;

  const screeningQuestionsEnabled = Boolean(
    siteConfig &&
      siteConfig.quote.displayOptionalSections.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions.length > 0,
  );

  const productModuleDefinitionQuoteSchema = productModuleAndSchema?.productModuleDefinitionQuoteSchema;
  const productModuleDefinition = productModuleAndSchema?.productModule.productModuleDefinition;

  const steps = sectionIndices.map((sectionIndex, idx) => {
    const stepKey = `quoteInputStep${sectionIndex}`;
    const isLastStep = idx === sectionIndices.length - 1;

    return {
      title: quote?.wording.title,
      description: getWording({ wording: quote?.wording.description }),
      key: stepKey,
      stepName: DefaultStepKeys.Quote,
      onBackClick: () => {
        if (idx === 0) {
          screeningQuestionsEnabled
            ? params.setCurrentStepKey(StaticSceneStepKeys.ScreeningQuestions)
            : history.push(baseUrl);
        } else {
          const previousSectionIndex = sectionIndices[idx - 1];
          params.setCurrentStepKey(`quoteInputStep${previousSectionIndex}`);
        }
      },
      continueButtonProps: {
        isLoading: isLoadingCreateQuote || isLoadingMainPage,
        disabled:
          isLoadingCreateQuote ||
          isLoadingMainPage ||
          !quoteSchemaFormDataParts[sectionIndex] ||
          !isValidQuoteParts[sectionIndex],
        isNextButtonHidden: isLastStep,
      },
      body: () => (
        <CreateQuotePackageStep
          sectionIndex={sectionIndex}
          prefillValues={prefillValues}
          setIsLoading={(isLoading) => {
            setLoadingCreateQuote(isLoading);
            setIsLoadingBackButton(isLoading);
          }}
          setIsLoadingMainPage={(isLoading) => setIsLoadingMainPage(isLoading)}
          isCompleted={!!isQuoteCompletedSteps[sectionIndex]}
          productModuleDefinition={productModuleDefinition}
          quoteSchemaFormDataParts={quoteSchemaFormDataParts}
          productModuleDefinitionQuoteSchema={productModuleDefinitionQuoteSchema}
          createQuoteButtonRef={createQuoteButtonRef}
          setIsValid={(isValid) => setIsValidQuoteParts({ ...isValidQuoteParts, [sectionIndex]: isValid })}
          setQuoteSchemaFormDataPart={(quoteData) => {
            setQuoteSchemaFormDataParts({ ...quoteSchemaFormDataParts, [sectionIndex]: quoteData });
          }}
          stepKey={stepKey}
          screeningQuestionsData={screeningQuestionsData}
          rootSchemaFormRef={rootSchemaFormQuoteRef}
          isLastStep={isLastStep}
          setQuotePackages={setQuotePackages}
          setCreateQuoteError={setCreateQuoteError}
          onNextCompleted={params.onNextCompleted} // Add this line
          isLoadingCreateQuote={isLoadingCreateQuote}
        />
      ),
      onNextClick: async () => {
        setIsQuoteCompletedSteps({ ...isQuoteCompletedSteps, [sectionIndex]: true });
        stepCompletedTrack({
          stepName: `${MixpanelStepNames.QuoteStep}_${sectionIndex}`,
        });
        if (isLastStep) {
          setIsQuoteCompleted(true);
          if (selectedQuote) {
            params.onNextCompleted && params.onNextCompleted();
          }
        } else {
          const nextSectionIndex = sectionIndices[idx + 1];
          params.setCurrentStepKey(`quoteInputStep${nextSectionIndex}`);
        }
      },
    };
  });

  return steps;
};
