import React, { createContext, useContext } from 'react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { Environment } from 'models/environment';
import {
  MixpanelDomain,
  MixPanelEventGenerators,
  MixpanelEventNames,
  MixpanelEventPropertiesMap,
  MixpanelExtraProperties,
} from './mix-panel-types';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { useSiteConfigContext } from 'style-context';
import { EmbeddedConfigSection } from 'site-config';
import { sanitizeUrl } from './mix-panel-utils';

// NB!!! Be cautious when changing these, as mixpanel dashboards rely on the property names
interface MixpanelBaseProperties {
  environment: Environment;
  organizationId: string;
  embedSessionId: string | undefined;
  productModuleKey: string;
  organizationName: string;
}
interface MixpanelContextValue {
  track: <K extends MixpanelEventNames>(
    eventName: K,
    payload: Exclude<MixpanelEventPropertiesMap[K], undefined>,
  ) => void;
}

export interface MixpanelBasePayload {
  domain: MixpanelDomain;
}

const MixpanelContext = createContext<MixpanelContextValue | undefined>(undefined);

export const useMixpanel = () => {
  const context = useContext(MixpanelContext);
  if (!context) {
    return { track: () => {} };
  }
  return context;
};

export const useMixPanelTrack = () => {
  const { track } = useMixpanel();
  const startedTrack = (params: { stepName: string; extraProperties?: MixpanelExtraProperties }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepStarted, { step: stepName, domain: MixpanelDomain.Sales, ...extraProperties });
  };

  const stepCompletedTrack = (params: { stepName: string; extraProperties?: MixpanelExtraProperties }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepCompleted, { step: stepName, domain: MixpanelDomain.Sales, ...extraProperties });
  };

  const actionStartedTrack = (params: { action: string; domain: MixpanelDomain }) => {
    const { action, domain } = params;
    track(MixpanelEventNames.ActionStarted, { action, domain });
  };

  return { startedTrack, stepCompletedTrack, actionStartedTrack };
};

export const MixpanelProvider: React.FC = ({ children }) => {
  const { embedParams } = useEmbedParamsContext();
  const { embedSessionId } = useEmbedSessionContext();
  const { siteConfig } = useSiteConfigContext();

  const internalMixPanelKey = process.env.REACT_APP_MIX_PANEL_KEY;
  const thirdPartyMixPanelKey = siteConfig?.[EmbeddedConfigSection.Settings]?.mixpanelProjectToken;

  if (internalMixPanelKey) {
    mixpanel.init(internalMixPanelKey, {}, 'internal');
  }

  if (thirdPartyMixPanelKey) {
    mixpanel.init(thirdPartyMixPanelKey, {}, 'external');
  }

  const track = <K extends MixpanelEventNames>(
    eventName: K,
    payload: Exclude<MixpanelEventPropertiesMap[K], undefined>,
  ) => {
    const base: MixpanelBaseProperties = {
      environment: embedParams.environment,
      organizationId: embedParams.organizationId,
      organizationName: embedParams.organizationName,
      embedSessionId,
      productModuleKey: embedParams.productModuleKey,
    };

    const mergedPayload = {
      $current_url: sanitizeUrl(window.location.href),
      $referrer: sanitizeUrl(document.referrer),
      $initial_referrer: sanitizeUrl(document.referrer),
      source: 'Embed',
      ...base,
      ...payload,
    } as unknown as MixpanelEventPropertiesMap[K];

    const generator = MixPanelEventGenerators[eventName];
    const finalPayload = generator ? generator(mergedPayload) : mergedPayload;

    const isTestEnvironment = process.env.NODE_ENV === 'test';
    const isLocalEnvironment = process.env.NODE_ENV === 'development';

    const typedMixPanel = mixpanel as unknown as { internal?: Mixpanel; external?: Mixpanel };

    if (!isTestEnvironment && !isLocalEnvironment) {
      typedMixPanel.internal?.track(eventName, finalPayload);
      typedMixPanel.external?.track(eventName, finalPayload);
    }
  };

  return <MixpanelContext.Provider value={{ track }}>{children}</MixpanelContext.Provider>;
};

export default MixpanelProvider;
