import React from 'react';
import { Col } from 'reactstrap';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { ApiError, usePolicyManagementUrl } from 'shared/api';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import {
  getColor,
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
  ProductModuleEmbedConfigSupportType,
} from 'site-config';
import { PolicyIssuingBanner } from 'policy-issuing/common/policy-issuing-banner';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { WarningIcon } from 'assets/warning-icon';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { AlterationPackage } from 'alteration-hooks/domain/alteration-package';
import { usePromiseLazy } from 'shared/hooks/promise';
import { applyAlterationPackageToPolicy } from 'alteration-hooks/actions/apply-alteration-package-to-policy';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { Policy } from 'policies/domain/policy';
import { ManagementView } from 'policy-management/policy-management-scene';
import { useHistory } from 'react-router-dom';
import { getPolicyById } from 'policies/actions/get-policy-by-id';
import { PolicyIssuingErrorBanner } from 'policy-issuing/quotes/views/policy-issuing-error-banner';
import { StyledQuotePackageList, StyledValidQuoteDisplay } from 'policy-issuing/quotes/views/quote-step-action-bar';
import { AlterationPackageActionItem } from './alteration-package-action-item';
import { Colors } from 'rootstrap/global-styles/colors';
import { useMixpanel } from 'context/mix-panel/mix-panel-context';
import { MixpanelDomain, MixpanelEventNames } from 'context/mix-panel/mix-panel-types';

interface AlterationPackageStepActionBarProps {
  error: ApiError | undefined;
  alterationPackages: AlterationPackage[] | undefined;
  isCreatingAlterationPackage: boolean;
  productModuleDefinition: ProductModuleDefinition | undefined;
  policy: Policy | undefined;
  setPolicy: (policy: Policy | undefined) => void;
  setView: (view: ManagementView) => void;
  rootSchemaFormAlterationPackageRef: React.MutableRefObject<any>;
}

// add loading state
export const AlterationStepActionBar = (params: AlterationPackageStepActionBarProps) => {
  const {
    error,
    isCreatingAlterationPackage,
    productModuleDefinition,
    alterationPackages: alterationPackage,
    policy,
    setPolicy,
    setView,
    rootSchemaFormAlterationPackageRef,
  } = params;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const history = useHistory();
  const policyDetailsUrl = usePolicyManagementUrl({ view: ManagementView.PolicyDetails, policyId: policy?.policyId });
  const { track } = useMixpanel();

  const { auth, organizationId, environment } = embedParams;

  const coverRejectedWording = getWording({
    wording: siteConfig?.settings.supportEmail || 'cover',
  });

  const { error: errorApplyingAlterationPackage, execute } = usePromiseLazy(async (alterationPackageId: string) => {
    if (!policy?.policyId) {
      throw new Error('Missing policy data');
    }

    if (!alterationPackageId) {
      throw new Error('Missing alteration package data');
    }

    await applyAlterationPackageToPolicy({
      auth,
      environment,
      organizationId,
      alterationPackageId: alterationPackageId,
      policyId: policy?.policyId,
    });

    track(MixpanelEventNames.PolicyAlterationApplied, {
      alteration: alterationPackageId,
      domain: MixpanelDomain.Management,
      policyId: policy.policyId,
      policyNumber: policy.policyNumber,
    });

    const updatedPolicy = await getPolicyById({ auth, environment, organizationId, policyId: policy.policyId });

    setPolicy(updatedPolicy);
    history.push(policyDetailsUrl);

    setView(ManagementView.PolicyDetails);
  }, []);

  if (isCreatingAlterationPackage) {
    return (
      <PolicyIssuingBanner>
        <StyledValidQuoteDisplay>
          <Col sm={12}>
            <p>Fetching updated cover...</p>
            <p style={{ paddingRight: 5 }}>
              <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color={Colors.Subscript} />
            </p>
          </Col>
        </StyledValidQuoteDisplay>
      </PolicyIssuingBanner>
    );
  }

  const fieldError = error?.fieldErrors && error?.fieldErrors[0];
  if (!alterationPackage && fieldError) {
    return (
      <PolicyIssuingErrorBanner
        body={`We're sorry! We are unable to provide you with ${coverRejectedWording} for now. If you'd like to
          receive further information, contact us at`}
      />
    );
  }

  if (error) {
    return <PolicyIssuingErrorBanner body={`${error.message}. Please try again or contact us at`} />;
  }

  if (!alterationPackage) {
    return (
      <PolicyIssuingBanner>
        <StyledQuotePackageList siteConfig={siteConfig}>
          <h5 id='quote-packages-header' className='quote-packages-header'>
            Please fill out the form above to update your cover.
          </h5>
        </StyledQuotePackageList>
      </PolicyIssuingBanner>
    );
  }

  return (
    <div
      id='alteration-footer-steps'
      onMouseOver={() => {
        rootSchemaFormAlterationPackageRef?.current?.resetActiveElement();
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }}
    >
      <PolicyIssuingBanner>
        <StyledQuotePackageList siteConfig={siteConfig}>
          <div>
            <h5 className='quote-packages-header bold'>{`Your coverage plan${
              alterationPackage.length > 1 ? 's' : ''
            }`}</h5>
          </div>
          {errorApplyingAlterationPackage && <ApplyAlterationPackageError siteConfig={siteConfig} />}
          {alterationPackage.map((alterationPackage) => (
            <AlterationPackageActionItem
              policy={policy as Policy}
              productModuleDefinition={productModuleDefinition}
              alterationPackage={alterationPackage}
              applyAlteration={execute}
            />
          ))}
        </StyledQuotePackageList>
      </PolicyIssuingBanner>
    </div>
  );
};

export const getBillingAbbreviation = (policyBillingFrequency: PolicyBillingFrequency) => {
  const billingAbbreviation = {
    [PolicyBillingFrequency.Daily]: '/day',
    [PolicyBillingFrequency.Monthly]: '/month',
    [PolicyBillingFrequency.OnceOff]: '',
    [PolicyBillingFrequency.Weekly]: '/week',
    [PolicyBillingFrequency.Yearly]: '/year',
  };

  return billingAbbreviation[policyBillingFrequency];
};

const SupportLinkTag = (params: {
  supportLink: string;
  supportLinkText: string;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}) => {
  const { siteConfig, supportLink, supportLinkText } = params;
  return (
    <a href={supportLink} style={{ color: getColor({ siteConfig, color: 'highlight' }) }}>
      {supportLinkText}
    </a>
  );
};

const StyledApplyAlterationPackageError = styled.span`
  margin-top: 10px;
  margin-bottom: -10px;
`;

const ApplyAlterationPackageError = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => {
  const { siteConfig } = params;
  const supportType = siteConfig?.settings.supportType;

  const supportLinkText =
    (supportType === ProductModuleEmbedConfigSupportType.Email
      ? `mailto:${siteConfig?.settings.supportEmail}`
      : siteConfig?.settings.supportUrl?.label) || '';

  const supportLink =
    (supportType === ProductModuleEmbedConfigSupportType.Email
      ? siteConfig?.settings.supportEmail
      : siteConfig?.settings.supportUrl?.url) || '';

  return (
    <StyledApplyAlterationPackageError>
      <div style={{ alignItems: 'center' }}>
        <WarningIcon siteConfig={siteConfig} />
      </div>
      <div>
        Could not complete action please try again or{' '}
        {supportType === ProductModuleEmbedConfigSupportType.Email ? 'contact' : 'visit'}&nbsp; us at
        <SupportLinkTag siteConfig={siteConfig} supportLink={supportLink} supportLinkText={supportLinkText} /> if the
        &nbsp;problem persists
      </div>
    </StyledApplyAlterationPackageError>
  );
};
