import { MixpanelPolicyPayload } from './generic-entity-types';

export interface PolicyAlterationAppliedPayload extends MixpanelPolicyPayload {
  alteration: string;
  policyId: string;
}

export function generateAlterationPackageAppliedProperties(
  payload: PolicyAlterationAppliedPayload,
): PolicyAlterationAppliedPayload {
  return payload;
}
