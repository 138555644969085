import { DisclaimerText } from 'landing-page/landing-page-footer';
import React from 'react';
import { getColor, getFontSize, getWording, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

export const InsurerDisclaimerContainer = () => {
  const { siteConfig } = useSiteConfigContext();

  const disclaimerImageEnabled = siteConfig?.footer.displayOptionalSections.disclaimerImage;
  const disclaimerImage = siteConfig?.footer.images.disclaimerUrl;
  const disclaimerWording = getWording({ wording: siteConfig?.footer.wording.disclaimer });
  const footerFontSize = getFontSize({ siteConfig, fontSize: 'footer' });

  return (
    <BottomContainer fontSize={footerFontSize} id={'insurer-disclaimer-container'} siteConfig={siteConfig}>
      {disclaimerImageEnabled && disclaimerImage && <img src={disclaimerImage} alt='' />}
      <DisclaimerText siteConfig={siteConfig} fontSize={footerFontSize}>
        {disclaimerWording}
      </DisclaimerText>
    </BottomContainer>
  );
};

const BottomContainer = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  fontSize: string;
}>`
  flex-direction: row;
  align-items: center;
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  font-size: 8px;
  line-height: inherit;
  font-style: normal;
  font-weight: normal;
  display: flex;

  div {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: ${({ fontSize }) => fontSize};
  }

  span {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: ${({ fontSize }) => fontSize};
  }

  div:nth-child(2) {
    padding-left: 15px;
  }

  img {
    max-height: 21px;
  }
`;
